import React from "react";
import { createRoot } from 'react-dom/client';

import {BrowserRouter, Route, Routes, Navigate} from "react-router-dom";

import AuthLayout from "./layouts/Auth.js";
import AdminLayout from "./layouts/Admin.js";
import Dashboard from "./views/Dashboard";
import Settings from "./views/Settings";
import CreatePostgresDeploymentIndex from "./views/Dashboard/components/CreateDeployment/postgres";
import CreateMySQLDeploymentIndex from "./views/Dashboard/components/CreateDeployment/mysql";
import CreateMSSQLDeploymentIndex from "./views/Dashboard/components/CreateDeployment/mssql";
import SignUp from "./views/Auth/SignUp";
import ConfirmEmailPrompt from "./views/Auth/ConfirmEmailPrompt";
import SignIn from "./views/Auth/SignIn";
import ChooseDeploymentType from "./views/Dashboard/components/ChooseDeploymentType";
import ConfirmEmail from "./views/Auth/ConfirmEmail";
import ForgotPassword from "./views/Auth/ForgotPassword";
import ResetPassword from "./views/Auth/ResetPassword";
import DeploymentDashboard from "./views/Dashboard/components/DeploymentDashboard";

const root = createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <Routes>
      <Route path={`auth`} element={<AuthLayout/>}>
        <Route path={`sign_up`} element={<SignUp/>} />
        <Route path={`sign_in`} element={<SignIn/>} />
        <Route path={`confirm_email/:token`} element={<ConfirmEmail/>} />
        <Route path={`confirm_email_prompt`} element={<ConfirmEmailPrompt/>} />
        <Route path={`forgot_password`} element={<ForgotPassword/>} />
        <Route path={`reset_password/:token`} element={<ResetPassword/>} />
      </Route>
      <Route path={`admin`} element={<AdminLayout/>} >
        <Route exact path={`deployment/postgres`} element={<ChooseDeploymentType databaseType='postgres' />} />
        <Route exact path={`deployment/mysql`} element={<ChooseDeploymentType databaseType='mysql'/>} />
        <Route exact path={`deployment/mssql`} element={<ChooseDeploymentType databaseType='mssql'/>} />
        <Route path={`deployment/postgres/:deployment_id`} element={<CreatePostgresDeploymentIndex />} />
        <Route path={`deployment/postgres/:deployment_id/dashboard`} element={<DeploymentDashboard />} />
        <Route path={`deployment/mysql/:deployment_id`} element={<CreateMySQLDeploymentIndex />} />
        <Route path={`deployment/mysql/:deployment_id/dashboard`} element={<DeploymentDashboard />} />
        <Route path={`deployment/mssql/:deployment_id`} element={<CreateMSSQLDeploymentIndex />} />
        <Route path={`deployment/mssql/:deployment_id/dashboard`} element={<DeploymentDashboard />} />
        <Route path={`dashboard`} element={<Dashboard />} />
        <Route path={`settings`} element={<Settings />} />
      </Route>
      <Route
        path="/"
        element={<Navigate replace to="/admin/dashboard" />} />
    </Routes>
  </BrowserRouter>,
);
