import {useEffect, useState} from "react";
import {get, put} from "../client/client";
import {Account} from "../types";

const useAccount = () => {
  const [account, setAccount] = useState<Account | null>(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    loadAccount();
  }, []);

  const loadAccount = async () => {
    try {
      const res = await get('/account');
      setAccount(res.data);
    } catch (err) {
      console.log(err);
      // @ts-ignore
      setError(err.response.data.error);
    }
  }
  const updateAccount = async (account: Account) => {
    setAccount(account);
    await put('/account', account);
  }

  return {updateAccount, account, error, loadAccount};
}

export default useAccount;