// Chakra imports
import {Box, Center, ChakraProvider, Flex, Image, Text, useColorModeValue,} from "@chakra-ui/react";
// Assets
import negativeLogo from "../../assets/img/negative-logo.svg";
import {useNavigate, useParams} from "react-router-dom";
import {post} from "../../client/client";
import React, {useEffect, useState} from "react";
import {PuffLoader} from "react-spinners";
import theme from "../../theme/theme";

const STATUS = {
  CONFIRMING: "confirming",
  ERROR: "error",
}

function ConfirmEmail() {
  const {token} = useParams();
  const [status, setStatus] = useState(STATUS.CONFIRMING);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    post("confirm_email", {token}).then(async () => {
      if (process.env.REACT_APP_ENV  === "production") {
        // Try catch because adblockers will block this
        try {
          fbq('track', 'Lead', {});
        } catch (e) {
          console.log(e);
        }
      }
      navigate("/admin/dashboard", {replace: true});
    })
      .catch((error) => {
        setStatus(STATUS.ERROR);
        setError(error.response.data.error);
      });
  }, []);

  return (
    <ChakraProvider theme={theme} resetCss={false} w='100%'>
      <Flex position='relative' style={{width: "100%", height: "100vh", overflowY: "scroll"}}>
        <Flex w='100%'>
          <Box
            bg={useColorModeValue("blue.700", "gray.800")}
            w='100%'
            h='100%'
            bgSize='cover'>
            <Center style={{height: "100%"}}>
              <Flex flexDirection={"column"}>
                <Center style={{top: "0px"}}>
                  <Image src={negativeLogo} w='80%'/>
                </Center>
                <div style={{marginTop: "20px"}}>
                  <Center>
                    <Text color={"gray.100"} fontSize={"2xl"} fontWeight={"bold"}>
                      {status === STATUS.CONFIRMING && "Confirming your email"}
                      {
                        status === STATUS.ERROR && "Email Confirmation Error"
                      }
                    </Text>
                  </Center>
                  <Center style={{marginTop: "15px"}}>
                    {status === STATUS.CONFIRMING &&
                      <PuffLoader
                        size={60}
                        color={"#ffffff"}
                        loading={true}
                      />
                    }
                    {
                      status === STATUS.ERROR &&
                      <Text style={{opacity: error ? 1 : 0}} color={"red.300"} textAlign={"center"}
                            fontSize={"sm"}>
                        {error}
                      </Text>
                    }
                  </Center>
                </div>
              </Flex>
            </Center>
          </Box>
        </Flex>
      </Flex>
    </ChakraProvider>

  );
}

export default ConfirmEmail;
