import React, {useEffect, useState} from "react";
import {Flex, Card, CardBody, Text, useColorModeValue, Button, Spacer} from "@chakra-ui/react";
import { FaArrowLeft } from "react-icons/fa";
import {get, put} from "../../../client/client";
import {withAccount} from "../../../contexts";
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Container } from '@chakra-ui/react'
import CardHeader from "../../../components/Card/CardHeader";
import {ViewIcon, ViewOffIcon} from "@chakra-ui/icons";

function FrameWrapper({ url }) {
  const [isLoading, setIsLoading] = React.useState(true);
  const ref = React.useRef();
  const [height, setHeight] = React.useState("100%");

  const onLoad = () => {
    setIsLoading(false);
  }

  return (
      <>
      {isLoading && (
          <div>Loading...</div>
      )}
      <iframe
          ref={ref}
          onLoad={onLoad}
          id="myFrame"
          src={url}
          width="100%"
          height={height}
          scrolling="no"
          frameBorder="0"
          style={{
            width: "100%",
            overflow: "auto",
          }}
      />
      </>
  );
}

const DeploymentDashboard = ({reloadAccount, account}) => {
  const navigate = useNavigate();
  const {deployment_id} = useParams();
  const [deployment, setDeployment] = useState(null);
  const [dashboardUrl, setDashboardUrl] = useState(null);
  const [title, setTitle] = useState("Deployment Dashboard");
  const textColor = useColorModeValue("gray.700", "white");

  useEffect(() => {
    if (deployment?.id !== deployment_id && account) {
      for (const deployment of account.deployments) {
        if (deployment.id === deployment_id) {
          setDeployment(deployment);
          return;
        }
      }
    }
  }, [account]);

  useEffect(() => {
    if (deployment?.id) {
      if (deployment?.deploymentName) {
        setTitle(`${deployment.deploymentName} Dashboard`);
      }

      get(`/deployments/${deployment.id}/dashboard_url`).then((res) => {
        setDashboardUrl(res.data);
      }).catch((err) => {
        console.log("error getting dashboard url", err);
      });
    }
  }, [deployment]);


  return deployment ? (
      <>
        <Flex direction='column' maxWidth={"90%"} margin={"auto"} style={{height: "100%", zIndex: "50", position: "relative"}}>
          <Card overflowX={{sm: "scroll", xl: "hidden"}} variant={"panel"} height={'100%'} minWidth={'90%'}>
            <CardHeader p='6px 10px 0px 20px'>
              <Button size='sm' onClick={() => navigate(`/admin/dashboard`) } style={{backgroundColor:"transparent"}}>
                <FaArrowLeft />
              </Button>
              <Text fontSize='xl' color={textColor} fontWeight='bold' pl={2}>
                {title}
              </Text>
              <Spacer />

              <Button size="sm" isDisabled mr={2}>
                Version: v20.7.3 (latest)
              </Button>
            </CardHeader>
            <CardBody>
              <FrameWrapper url={dashboardUrl} />
            </CardBody>
          </Card>
        </Flex>
      </>
  ) : <></>
}

export default withAccount(DeploymentDashboard);
