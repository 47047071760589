// Chakra imports
import {Box, Center, ChakraProvider, Flex, Image, Text, useColorModeValue,} from "@chakra-ui/react";
// Assets
import negativeLogo from "../../assets/img/negative-logo.svg";
import {React} from "react";
import theme from "../../theme/theme";


function ConfirmEmailPrompt() {
  return (
    <ChakraProvider theme={theme} resetCss={false} w='100%'>
      <Flex position='relative' style={{width: "100%", height: "100vh", overflowY: "scroll"}}>
        <Flex w='100%'>
          <Box
            bg={useColorModeValue("blue.700", "gray.800")}
            w='100%'
            h='100%'
            bgSize='cover'>
            <Center style={{height: "100%"}}>
              <Flex flexDirection={"column"}>
                <Center style={{top: "0px"}}>
                  <Image src={negativeLogo} w='40%'/>
                </Center>
                <div style={{marginTop: "20px"}}>
                  <Center>
                    <Text color={"gray.100"} fontSize={"2xl"} fontWeight={"bold"}>
                      Almost done!
                      Check your email for a confirmation link.
                    </Text>
                  </Center>
                  <Center>
                    <Text color={"gray.100"} fontSize={"2m"} fontWeight={"bold"}>
                      If you don't see the email, please check your spam folder.
                    </Text>
                  </Center>
                </div>
              </Flex>
            </Center>
          </Box>
        </Flex>
      </Flex>
    </ChakraProvider>

  );
}

export default ConfirmEmailPrompt;
