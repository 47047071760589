import { mode } from '@chakra-ui/theme-tools';

export const globalStyles = {
	colors: {
		gray: {
			700: '#1f2733'
		},
		teal: {
      "50": "#E8FDF8",
      "100": "#BFF8EB",
      "200": "#95F4DE",
      "300": "#65dcbe",

			"400": "#42EBC4",


      // "500": "#19E6B7",
      // "600": "#14B892",
			500: "rgb(88, 210, 199)",

      "700": "#0F8A6E",
      "800": "#0A5C49",
      "900": "#052E25"
    },
		blue: {
			700: "#11134E"
			// 600: "red",

		}
	},
	styles: {
		global: (props) => ({
			body: {
				bg: mode('gray.50', 'gray.800')(props),
				fontFamily: "'Roboto', sans-serif"
			},
			html: {
				fontFamily: "'Roboto', sans-serif"
			}
		})
	}
};
