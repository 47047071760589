// Chakra imports
import {ChakraProvider, extendTheme, Portal} from '@chakra-ui/react';
// Layout components
import AdminNavbar from '../components/Navbars/AdminNavbar.js';
import Sidebar from '../components/Sidebar';
import React, {useContext, useEffect, useState} from 'react';
import {Outlet, Route, Routes, useNavigate} from 'react-router-dom';
import routes from '../routes.js';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
// Custom Chakra theme
import theme from '../theme/theme.js';
// Custom components
import MainPanel from '../components/Layout/MainPanel';
import {AccountContext} from "../contexts";
import useAccount from "../hooks/useAccount";

import {Box, Drawer, DrawerContent, useDisclosure} from "@chakra-ui/react";

export default function Dashboard(props) {
    const {...rest} = props;

    const navigate = useNavigate();
    // states and functions
    const [sidebarVariant, setSidebarVariant] = useState('transparent');
    const [fixed, setFixed] = useState(false);

    const {account, error, updateAccount, loadAccount} = useAccount();

    useEffect(() => {
        if (error !== null) {
            navigate("/auth/sign_in", {replace: true}); // TODO: should be sign in
        }
    }, [error]);

    useEffect(() => {
        if (account && !account.isEmailConfirmed) {
            navigate('/auth/confirm_email_prompt', {replace: true});
        }
    }, [account]);

    const getActiveRoute = (routes) => {
        let activeRoute = 'Default Brand Text';
        for (let i = 0; i < routes.length; i++) {
            if (routes[i].collapse) {
                let collapseActiveRoute = getActiveRoute(routes[i].views);
                if (collapseActiveRoute !== activeRoute) {
                    return collapseActiveRoute;
                }
            } else if (routes[i].category) {
                let categoryActiveRoute = getActiveRoute(routes[i].views);
                if (categoryActiveRoute !== activeRoute) {
                    return categoryActiveRoute;
                }
            } else {
                if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
                    return routes[i].name;
                }
            }
        }
        return activeRoute;
    };

    // This changes navbar state(fixed or not)
    const getActiveNavbar = (routes) => {
        let activeNavbar = false;
        for (let i = 0; i < routes.length; i++) {
            if (routes[i].category) {
                let categoryActiveNavbar = getActiveNavbar(routes[i].views);
                if (categoryActiveNavbar !== activeNavbar) {
                    return categoryActiveNavbar;
                }
            } else {
                if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
                    if (routes[i].secondaryNavbar) {
                        return routes[i].secondaryNavbar;
                    }
                }
            }
        }
        return activeNavbar;
    };
    const {isOpen, onOpen, onClose} = useDisclosure();
    document.documentElement.dir = 'ltr';
    // Chakra Color Mode
    return (
        <ChakraProvider theme={theme} resetCss={false}>
            <AccountContext.Provider value={{account, updateAccount, reloadAccount: loadAccount}}>
                <Sidebar
                    routes={routes.filter((route) => route.category !== 'account')}
                    display='none'
                    sidebarVariant={sidebarVariant}
                    {...rest}
                />
                <MainPanel
                    w={{
                        base: '100%',
                        xl: 'calc(100% - 260px)'
                    }}>
                        <AdminNavbar
                            onOpen={onOpen}
                            brandText={getActiveRoute(routes)}
                            secondary={getActiveNavbar(routes)}
                            fixed={fixed}
                            {...rest}
                        />
                    <Box marginTop="80px" height="calc(100vh - 80px)" maxHeight="calc(100vh - 80px)">
                        <Outlet/>
                    </Box>
                </MainPanel>
            </AccountContext.Provider>
        </ChakraProvider>
    );
}
