import React, {useEffect, useState} from "react";
import {Flex, Grid, GridItem, Text} from "@chakra-ui/react";
import {Step, Steps, useSteps} from "chakra-ui-steps";
import {createSteps} from "./consts";
import useInterval from "../../../../../hooks/useInterval";
import {get, put} from "../../../../../client/client";
import Success from "./Success";
import {USER_FIELDS} from "../../../../../types";
import {withAccount} from "../../../../../contexts";
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Container } from '@chakra-ui/react'

const Index = ({reloadAccount, account}) => {
  const { deployment_id } = useParams();
  const [deployment, setDeployment] = useState(null);

  useEffect(() => {
    if (deployment?.id !== deployment_id && account) {
      console.log("setting deployment to", deployment_id)

      for (const deployment of account.deployments) {
        if (deployment.id === deployment_id) {
          setDeployment(deployment);
          return;
        }
      }
    }
  }, [account]);

  const {nextStep, prevStep, reset, activeStep, setStep} = useSteps({
    initialStep: 0,
  });

  useEffect(() => {
    if (deployment && !deployment.epsioSaidHello) {
      setStep(0);
    } else if (deployment && deployment.epsioSaidHello && !deployment.connected) {
      setStep(1);
    } else if (deployment && deployment.connected) {
      setStep(2);
    }
  }, [deployment]);

  useInterval(() => {
    if (!deployment) {
      return;
    }
    get(`/deployments/${deployment.id}`).then((res) => {
      const newDeployment = res.data;
      let updateFields = {};

      for (const key in newDeployment) {
        if (!USER_FIELDS.includes(key) && newDeployment[key] !== deployment[key]) {
          updateFields[key] = newDeployment[key];
        }
      }

      if (Object.keys(updateFields).length > 0) {
        console.log("updating deployment", updateFields);
        setDeployment({...deployment, ...updateFields});
      }
    }).catch((err) => {
      console.log("error getting deployment", err);
      reloadAccount();
    });
  }, 1000);

  const updateDeployment = (newDeployment) => {
    setDeployment(newDeployment);
    return put("/deployments/" + newDeployment.id, newDeployment);
  };

  return deployment ? (
      <>
        <Container minHeight={'100%'} maxHeight={'100%'} minWidth={'90%'}>
          {
            deployment.replicationInitialized && <Success onFinish={reloadAccount}/>
          }
          <Steps variant={"simple"} activeStep={activeStep} colorScheme="blue" hidden={deployment.replicationInitialized}>
            {createSteps(deployment).map(({label, component, header, description}, index) => (

                <Step label={`${index + 1}. ${label}`} key={label}>
                  {
                      !deployment.replicationInitialized &&
                      <Grid templateRows={"min-content min-content 1fr"} gap={2}
                            style={{
                              justifyItems: "center",
                              gridAutoColumns: "1fr",
                              margin: "2% auto",
                              minHeight: "0",
                              minWidth: "0",
                              height: "100%",
                              maxWidth: "90%"
                            }}>
                        <GridItem>
                          <Text fontSize={"lg"} fontWeight={"bold"}>{header}</Text>
                        </GridItem>
                        <GridItem>
                          <Text fontSize={"md"} color={"gray.500"} textAlign={"center"}
                                maxWidth={"900px"}>{description}</Text>
                        </GridItem>
                        <GridItem>
                          <Container w={"100%"} maxWidth={"100%"}>
                            <Flex style={{maxHeight: "inherit"}} h="100%" w={"calc(100wh - 230px)"}
                                  maxW={"inherit"}>
                              {React.createElement(component, {deployment, updateDeployment, reloadAccount})}
                            </Flex>
                          </Container>
                        </GridItem>
                      </Grid>
                  }
                </Step>
            ))}
          </Steps>
        </Container>
      </>
  ) : <></>
}

export default withAccount(Index);
