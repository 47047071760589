import {
  Flex,
  Text
} from "@chakra-ui/react";
import React from "react";
import StatusLabel from "./StatusLabel";
import {Deployment} from "../../../../../types";
import DeleteDeploymentButton from "../../DeleteDeploymentButton";

const Index = ({deployment, updateDeployment, reloadAccount}: {deployment: Deployment, reloadAccount: any, updateDeployment: (newDeployment: Deployment) => Promise<void>}) => {
  return (
    <div style={{width: "100%", maxWidth: "950px", height: "100%", marginTop: "30px"}}>

      <Flex style={{height: "60%"}} flexDirection={"column"} justifyContent={"space-evenly"}>

      <div style={{marginTop: "20px", marginLeft: "16px", marginRight: "16px"}}>
        <Text color={"gray.500"}>
          Epsio will notify the cloud it has been installed, and you will automatically move to the next step.
        </Text>
        <div style={{marginTop: "20px", marginLeft: "-4px"}}>
         <StatusLabel waitingText={"Waiting for Epsio to say hello..."}
                       finishedText={"Epsio said hello! Finished."}
                       error={null}
                       timeout={false}
                        waiting={!deployment.epsioSaidHello}/>
          </div>
      </div>
      </Flex>
        <div style={{position: "fixed", right: "55px", zIndex: 999, bottom: 10}}>
            <DeleteDeploymentButton variant={"simple"} deployment={deployment} reloadAccount={reloadAccount}/>
        </div>
    </div>
  )
}

export default Index;