import React, {useEffect, useState} from 'react';
import Card from "../../../../../components/Card/Card";
import CardHeader from "../../../../../components/Card/CardHeader";
import {
  Box,
  Button,
  Center, Flex,
  FormControl,
  FormLabel, Grid, GridItem,
  Heading, Image,
  Input,
  Tab,
  TabList, TabPanel,
  TabPanels, Tabs,
  Text, VStack
} from "@chakra-ui/react";
import {CopyBlock} from "react-code-blocks";
import {BsArrow90DegDown} from "react-icons/bs";
import CardBody from "../../../../../components/Card/CardBody";
import {CodeTheme} from "./consts";
import StatusLabel from "./StatusLabel";
import {Deployment} from "../../../../../types";
import DeleteDeploymentButton from "../../DeleteDeploymentButton";


const Link = ({href, children}: { href: string, children: any }) => <a href={href} target={"_blank"}
                                                                       style={{color: "var(--chakra-colors-blue-500)"}}>{children}</a>

const Spacer = () => <div style={{height: "10px"}}/>

const SelfHostedInstructions = () => <div>
  <div>
    <div>To check if your MSSQL instance is already configured to work with Epsio, you can run the following commands:
    </div>
    <Spacer/>
    <CopyBlock theme={CodeTheme} language="sql" text={"-- Required configuration: true\n" +
        "SELECT is_cdc_enabled FROM sys.databases WHERE name = DB_NAME();\n" +
        "\n" +
        "-- Required configuration: ON\n" +
        "SELECT snapshot_isolation_state_desc FROM sys.databases WHERE name=DB_NAME();"} showLineNumbers={false}
               codeBlock/>
    <Spacer/>
    <div>
      If not already enabled, follow the steps below to enable CDC.
    </div>
    <Spacer/>
    <div>
      <b>Step 1:</b> Enable CDC on your MSSQL instance.
      <p>
        Edit your mysql conf file and add the following configurations:
      </p>
      <Spacer/>
      <CopyBlock theme={CodeTheme} language="" text={"EXEC sys.sp_cdc_enable_db;"} showLineNumbers={false}
                 codeBlock/>
    </div>
  </div>
  <div style={{marginTop: "30px"}}>
    <b>Step 2:</b> Enable SNAPSHOT_ISOLATION.
    <Spacer/>
    <CopyBlock theme={CodeTheme} language="" text={"ALTER DATABASE CURRENT SET ALLOW_SNAPSHOT_ISOLATION ON;"}
               showLineNumbers={false}
               codeBlock/>
  </div>
  <div style={{marginTop: "30px"}}>
    <b>Step 3:</b> Verify that all the new configurations are enabled by running the following commands:
    <Spacer/>
    <CopyBlock theme={CodeTheme} language="sql" text={"-- Required configuration: true\n" +
        "SELECT is_cdc_enabled FROM sys.databases WHERE name = DB_NAME();\n" +
        "\n" +
        "-- Required configuration: ON\n" +
        "SELECT snapshot_isolation_state_desc FROM sys.databases WHERE name=DB_NAME();"} showLineNumbers={false}
               codeBlock/>
    <Spacer/>
  </div>
</div>


const ConfigureReplication = ({deployment, updateDeployment, reloadAccount}: {
  deployment: Deployment,
  reloadAccount: any,
  updateDeployment: (newDeployment: Deployment) => Promise<void>
}) => {

  const [verifyingReplication, setVerifyingReplication] = useState(false);

  const verifyReplication = () => {
    setVerifyingReplication(true);
    updateDeployment({
      ...deployment,
        databaseInitializationSetup: true,
        databaseInitializationError: null,
      replicationError: null,
      replicationSetup: true
    });
  }

  useEffect(() => {
    if (deployment.replicationError || deployment.databaseInitializationError) {
      setVerifyingReplication(false);
    }
  }, [deployment.replicationError, deployment.databaseInitializationError]);

  return (
      <Center>
          <Grid
              marginTop={"20px"}
              h="100%"
              w={"100%"}
              templateColumns='repeat(auto-fit, 1fr)'
              gap={"30px"}
              templateAreas={`
                "ConfigureReplication ValidateConfiguration"
              `}
          >
            <GridItem area={"ValidateConfiguration"} height={'100%'}>
              <Flex direction={"column"} height={'calc(100vh - 350px)'}>
                <Card height={"100%"}>
                    <Tabs style={{height: "100%"}} display={"grid"}>
                      <TabList>
                        <Tab>Self Hosted</Tab>
                        {/*<Tab>AWS RDS</Tab>*/}
                        {/*<Tab>AWS Aurora</Tab>*/}
                      </TabList>

                    <TabPanels style={{overflow: "auto", maxHeight: "100%", height: "100%"}}>
                      <TabPanel>
                        <SelfHostedInstructions/>
                      </TabPanel>
                      {/*<TabPanel>*/}
                      {/*  <AWSRDSInstructions />*/}
                      {/*</TabPanel>*/}
                      {/*<TabPanel>*/}
                      {/*  <AWSAuroraInstructions />*/}
                      {/*</TabPanel>*/}
                    </TabPanels>
                      <Flex direction={"column"} style={{marginLeft: "1em"}} height={"100%"}>
                        <div style={{marginBottom: "10px", marginTop: "10px", height: "100%"}}>
                          <Button onClick={verifyReplication} isLoading={verifyingReplication} colorScheme={"blue"}
                                  style={{padding: "20px"}} fontSize={"md"}>Validate Configuration</Button>
                        </div>
                        {
                            (deployment.replicationError || verifyingReplication) &&
                            <StatusLabel waitingText={"Verifying replication..."}
                                         waiting={!deployment.replicationInitialized}
                                         error={deployment.replicationError}
                                         finishedText={"Epsio is ready to receive replication!"}
                            />
                        }
                      </Flex>
                    </Tabs>

                </Card>
                <Flex align={"flex-end"} justify={"flex-end"} style={{ height: "100%" }}></Flex>
                <div style={{position: "fixed", right: "55px", zIndex: 999, bottom: 10}}>
                  <DeleteDeploymentButton variant={"simple"} deployment={deployment} reloadAccount={reloadAccount}/>
                </div>
              </Flex>
            </GridItem>
          </Grid>

      </Center>
  )
};

export default ConfigureReplication;