import React, { useState, useRef } from 'react';
import { Link, Button, AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogBody, AlertDialogFooter } from '@chakra-ui/react';
import {delete_} from "../../../client/client";
import { useNavigate } from 'react-router-dom';
import {MdCancel} from "react-icons/md";

const DeleteDeploymentButton = ({ deployment, reloadAccount, variant }) => {
  const [showingDeleteDialog, setShowingDeleteDialog] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const cancelRef = useRef();

  const navigate = useNavigate();
  const deleteDeployment = async () => {
    setDeleting(true);
    delete_(`deployments/${deployment.id}`).then(() => {
      reloadAccount().then(() => {
        navigate("/admin/dashboard");
        setDeleting(false);
        setShowingDeleteDialog(false);
      });
    });
  };

    const renderTrigger = () => {
    if (variant === 'text') {
      return <Button colorScheme="red" size="sm" onClick={() => setShowingDeleteDialog(true)}>Delete</Button>;
    }
    if (variant === 'icon') {
      return (
        <Button colorScheme="red" size="sm" onClick={() => setShowingDeleteDialog(true)} title="Delete Deployment" variant="ghost">
          <MdCancel />
        </Button>
      );
    }
    return (
      <Button variant='outline' colorScheme="red" onClick={() => setShowingDeleteDialog(true)}>
        Delete Deployment
      </Button>
    );
  };

  return (
    <>
      {renderTrigger()}
      <AlertDialog
        isOpen={showingDeleteDialog || deleting}
        leastDestructiveRef={cancelRef}
        onClose={() => setShowingDeleteDialog(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Delete Deployment
            </AlertDialogHeader>
            <AlertDialogBody>
              Are you sure?
              <br/>
              {/* TODO: need to add a new case for the terraform type */}
              {deployment.deploymentType === "AWS"
                ? 'All resources created by Epsio will be deleted'
                : 'If you would like to stop epsio, you will need to run `sudo epsio uninstall` on the machine Epsio was installed.'
              }
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button isDisabled={deleting} ref={cancelRef} onClick={() => setShowingDeleteDialog(false)}>
                Cancel
              </Button>
              <Button colorScheme='red' isLoading={deleting} onClick={deleteDeployment} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default DeleteDeploymentButton;