// import
import Settings from "./views/Settings";
import Tables from "./views/Dashboard";

import {
  HomeIcon, SettingsIcon 
} from "./components/Icons/Icons";

const dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: <HomeIcon color="inherit" />,
    component: Tables,
    layout: "/admin",
  },
  {
    path: "/settings",
    name: "Settings",
    icon: <SettingsIcon color="inherit" />,
    component: Settings,
    layout: "/admin",
  }
];
export default dashRoutes;
