import {
    Alert,
    AlertIcon,
    Box,
    Button,
    Card,
    CardBody,
    CardHeader,
    Center,
    Flex, FormControl,
    FormLabel,
    Heading,
    Spacer,
    theme,
    Select,
    useClipboard,
} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {Elements} from '@stripe/react-stripe-js';

import CardSetupForm from './CardSetupForm';
import {loadStripe} from '@stripe/stripe-js';
import {Input, InputGroup, InputRightElement} from '@chakra-ui/react'
import {Text} from '@chakra-ui/react'
import {Account} from "../../types";
import moment from "moment";
import {CopyIcon, ViewIcon, ViewOffIcon} from "@chakra-ui/icons";


const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || '');

const Settings = ({account, reloadAccount, updateAccount}: {
    account: Account,
    reloadAccount: any,
    updateAccount: any
}) => {
    const [removeLoading, setRemoveLoading] = useState(false);
    const [datadogApiKey, setDatadogApiKey] = useState('');
    const [datadogSite, setDatadogSite] = useState('');
    const gap = theme.space[5];

    const userToken = account.token || '';
    const userDatadogApiKey = account.datadogApiKey || '';

    useEffect(() => {
        if (account.datadogApiKey) {
            setDatadogApiKey(account.datadogApiKey);
        }
        if (account.datadogSite) {
            setDatadogSite(account.datadogSite);
        }
    }, [account]);

    const removeCard = () => {
        setRemoveLoading(true);
        updateAccount({...account, cardSetup: false}).then(() => {
            setRemoveLoading(false);
        });
    }

    const saveDatadogInfo = () => {
        updateAccount({...account, datadogApiKey: datadogApiKey, datadogSite: datadogSite}).then(() => {
            reloadAccount();
        });
    }

    const removeDatadogInfo = () => {
        setDatadogSite('');
        setDatadogApiKey('');
        updateAccount({...account, datadogApiKey: null, datadogSite: null}).then(() => {
            reloadAccount();
        });
    }

    const [showToken, setShowToken] = useState(false);
    const {hasCopied, onCopy} = useClipboard(userToken);

    const handleShowClick = () => setShowToken(!showToken);

    const displayValue = (value: string) => {
        return showToken ? value : `${value.slice(0, -4).replace(/./g, '*')}${value.slice(-4)}`;
    };

    return <div style={{height: "100%"}}>
        <Center style={{minHeight: "80%", flexDirection: "column", gap}} pb={gap}>
            <Card style={{minWidth: "60%", minHeight: "20%"}} hidden={userToken === ''}>
                <CardHeader pb={0}>
                    <Heading size='md'>Access Token</Heading>
                </CardHeader>
                <CardBody>
                    <Text as='b'>Copy your access token to create Docker/K8s deployments without the web console</Text>
                    <br/>
                    <br/>

                    <InputGroup size='md'>
                        <Input
                            type='text'
                            value={displayValue(userToken)}
                            isReadOnly
                        />
                        <InputRightElement width='8rem'>
                            <Button size='sm' onClick={handleShowClick} style={{backgroundColor: "transparent"}}>
                                {showToken ? <ViewIcon/> : <ViewOffIcon/>}
                            </Button>
                            <Button size='sm' ml={2} onClick={onCopy} style={{backgroundColor: "transparent"}}>
                                {hasCopied ? 'Copied' : <CopyIcon/>}
                            </Button>
                        </InputRightElement>
                    </InputGroup>
                </CardBody>
            </Card>
            <Card style={{minWidth: "60%", minHeight: "20%"}} hidden={userToken === ''}>
                <CardHeader pb={0}>
                    <Heading size='md'>Datadog Alerts and Monitoring</Heading>
                </CardHeader>
                <CardBody>

                    {
                        account.datadogApiKey ? <><Alert status='info'>
                            Datadog API Key is setup (site&nbsp;{account.datadogSite})
                        </Alert>
                            <br/>
                            <Button colorScheme='red' onClick={removeDatadogInfo}>Remove Datadog API Key</Button>
                        </> : <>
                            <Text as='b'>Configure a Datadog API key and Region to send monitoring data and alerts to
                                your
                                Datadog account</Text>
                            <br/>
                            <br/>

                            <div style={{display: "flex", paddingBottom: "15px"}}>
                                <InputGroup size='md' style={{paddingRight: "10px", flex: "5"}}>
                                    <Input
                                        type='text'
                                        placeholder='API Key'
                                        value={datadogApiKey}
                                        onChange={(e) => setDatadogApiKey(e.target.value)}
                                    />
                                </InputGroup>
                                <div style={{flex: 3}}>
                                    <Select placeholder='Region' value={datadogSite}
                                            onChange={(c) => setDatadogSite(c.target.value)}>
                                        <option value='datadoghq.com'>US1</option>
                                        <option value='us3.datadoghq.com'>US3</option>
                                        <option value='us5.datadoghq.com'>US5</option>
                                        <option value='datadoghq.eu'>EU1</option>
                                        <option value='ddog-gov.com'>US1-FED</option>
                                        <option value='ap1.datadoghq.com'>AP1</option>
                                    </Select>
                                </div>
                            </div>
                            <Button colorScheme='blue' mt={2} onClick={saveDatadogInfo}
                                    isDisabled={datadogSite === "" || datadogSite === null || datadogApiKey === ""}>Save</Button>
                        </>
                    }
                </CardBody>
            </Card>
            <Card style={{minWidth: "60%", minHeight: "30%"}}>
                <CardHeader pb={0}>
                    <Heading size='md'>Billing</Heading>
                </CardHeader>
                <CardBody>
                    {
                        account.cardSetup ? <><Alert status='info'>
                            Your next billing date is&nbsp;
                            <b>{moment(account.nextBillingDate).format('MMM DD, YYYY')}</b>
                        </Alert>
                            <br/>
                            <Button colorScheme='red' onClick={removeCard}>Remove card</Button>
                        </> : <>
                            <Text as='b'>Add a credit card to continue using Epsio after your free trial</Text>
                            <br/>
                            <br/>
                            <Elements stripe={stripePromise}>
                                {/* @ts-ignore*/}
                                <CardSetupForm account={account} updateAccount={updateAccount}
                                               reloadAccount={reloadAccount}/>
                            </Elements>
                        </>
                    }
                </CardBody>
            </Card>
        </Center>
    </div>
}

export default Settings;
