import React, {useEffect, useState} from 'react';
import Card from "../../../../../components/Card/Card";
import CardHeader from "../../../../../components/Card/CardHeader";
import {
  Box,
  Button,
  Center, Flex,
  FormControl,
  FormLabel, Grid, GridItem,
  Heading, Image,
  Input,
  Tab,
  TabList, TabPanel,
  TabPanels, Tabs,
  Text, VStack
} from "@chakra-ui/react";
import {CopyBlock} from "react-code-blocks";
import {BsArrow90DegDown} from "react-icons/bs";
import CardBody from "../../../../../components/Card/CardBody";
import {CodeTheme} from "./consts";
import mysql_aurora1 from "../../../../../assets/img/mysql_aurora/Parameters-group.png";
import mysql_aurora2 from "../../../../../assets/img/mysql_aurora/New-Setting.png";
import mysql_aurora4 from "../../../../../assets/img/mysql_aurora/Modify-Screenshot.png";
import mysql_aurora5 from "../../../../../assets/img/mysql_aurora/Apply-now.png";
import mysql_aurora6 from "../../../../../assets/img/mysql_aurora/Params-synced.png";
import mysql_rds1 from "../../../../../assets/img/mysql_rds/Parameters-group.png";
import mysql_rds2 from "../../../../../assets/img/mysql_rds/New-Setting.png";
import mysql_rds4 from "../../../../../assets/img/mysql_rds/Modify-Screenshot.png";
import mysql_rds5 from "../../../../../assets/img/mysql_rds/Apply-now.png";
import mysql_rds6 from "../../../../../assets/img/mysql_rds/Params-synced.png";
import StatusLabel from "./StatusLabel";
import {Deployment} from "../../../../../types";
import DeleteDeploymentButton from "../../DeleteDeploymentButton";


const Link = ({href, children}: { href: string, children: any }) => <a href={href} target={"_blank"}
                                                                       style={{color: "var(--chakra-colors-blue-500)"}}>{children}</a>

const Spacer = () => <div style={{height: "10px"}}/>

const AWSAuroraInstructions = () => {
  return (
      <div>
        <div>To check if your Aurora cluster is already configured to work with Epsio, you can run the following commands:
        </div>
        <Spacer/>
        <CopyBlock theme={CodeTheme} language="sql" text={"-- Required configuration: ON\n" +
            "SHOW VARIABLES LIKE 'log_bin';\n" +
            "\n" +
            "-- Required configuration: ON\n" +
            "SHOW VARIABLES LIKE 'log_bin_trust_function_creators';\n" +
            "\n" +
            "-- Required configuration: ROW\n" +
            "SHOW VARIABLES LIKE 'binlog_format';\n" +
            "\n" +
            "-- Value should be 1 hour or bigger\n" +
            "CALL mysql.rds_show_configuration();"} showLineNumbers={false}
                   codeBlock/>
        <Spacer/>
        <div>
          If any of the above does not show the required configuration, follow the steps below to reconfigure.
        </div>
        <Spacer/>
        <Spacer/>
        <div><b>Configure binary logging</b></div>
        <Spacer/>
        <div>
          <div><b>Step 1:</b> Create a new parameter group</div>
          <Spacer/>
          <p>
            To set custom cluster parameter group for your instance,
            <Link href={"https://docs.aws.amazon.com/AmazonRDS/latest/UserGuide/USER_WorkingWithDBClusterParamGroups.html"}> create a custom DB cluster parameter group</Link> and then associate it with your instance.
            Custom parameters cannot be set on the default Aurora CLuster parameter groups.
          </p>
          <Spacer/>
          <Image
              src={mysql_aurora1}
              width={"600px"}
          />

        </div>
        <div style={{marginTop: "30px"}}>
          <b>Step 2:</b> Edit the custom parameter group, and set:
          <div>binlog_format -{'>'} "ROW"</div>
          <div>log_bin_trust_function_creators -{'>'} 1</div>

          <Spacer />
          <Image src={mysql_aurora2} width={"600px"}/>
        </div>
        <div style={{marginTop: "30px"}}>
          <b>Step 3:</b> Update your Aurora cluster to <Link
            href="https://docs.aws.amazon.com/AmazonRDS/latest/UserGuide/USER_WorkingWithDBClusterParamGroups.html">use the new parameter group</Link>
          <Spacer />
          <Image src={mysql_aurora4} width={"600px"}/>
          <Spacer />
          <p>Make sure you choose "Apply Immediately" to apply the changes immediately.</p>
          <Spacer />
          <Image src={mysql_aurora5} width={"600px"}/>
        </div>
        <div style={{marginTop: "30px"}}>
          <b>Step 4:</b> Restart your Aurora cluster for the changes to take affect.
          <p>You'll know that the changes have taken affect when the status of your Aurora Instance Parameter Group changes to "In Sync".</p>
          <Spacer />
          <Image src={mysql_aurora6} width={"400px"}/>
        </div>
        <div style={{marginTop: "30px"}}>
          <b>Step 5:</b>Verify that all the new configurations are enabled by running the following commands:
          <Spacer />
          <CopyBlock theme={CodeTheme} language="sql" text={"-- Required configuration: ON\n" +
              "SHOW VARIABLES LIKE 'log_bin';\n" +
              "\n" +
              "-- Required configuration: ON\n" +
              "SHOW VARIABLES LIKE 'log_bin_trust_function_creators';\n" +
              "\n" +
              "-- Required configuration: ROW\n" +
              "SHOW VARIABLES LIKE 'binlog_format';"} showLineNumbers={false}
                     codeBlock/>
          <Spacer/>
        </div>
        <Spacer/>
        <div><b>Configure Retention Policy</b></div>
        <Spacer/>
        <div>
          <div><b>Step 1:</b> Edit the Aurora retention policy:</div>
          <Spacer/>
          <CopyBlock theme={CodeTheme} language="sql" text={"CALL mysql.rds_set_configuration('binlog retention hours', 3);"} showLineNumbers={false}
                     codeBlock/>
        </div>
        <div >
          <b>Step 2:</b> Verify that the retention policy is set correctly:
          <Spacer/>
          <CopyBlock theme={CodeTheme} language="sql" text={"CALL mysql.rds_show_configuration();\n"} showLineNumbers={false}
                     codeBlock/>
        </div>
      </div>
  )
}


const AWSRDSInstructions = () => {
  return (
      <div>
        <div>To check if your RDS instance is already configured to work with Epsio, you can run the following commands:
        </div>
        <Spacer/>
        <CopyBlock theme={CodeTheme} language="sql" text={"-- Required configuration: ON\n" +
            "SHOW VARIABLES LIKE 'log_bin';\n" +
            "\n" +
            "-- Required configuration: ON\n" +
            "SHOW VARIABLES LIKE 'log_bin_trust_function_creators';\n" +
            "\n" +
            "-- Required configuration: ROW\n" +
            "SHOW VARIABLES LIKE 'binlog_format';\n" +
            "\n" +
            "-- Value should be 1 hour or bigger\n" +
            "CALL mysql.rds_show_configuration();"} showLineNumbers={false}
                   codeBlock/>
        <Spacer/>
        <div>
          If any of the above does not show the required configuration, follow the steps below to reconfigure.
        </div>
        <Spacer/>
        <Spacer/>
        <div><b>Configure binary logging</b></div>
        <Spacer/>
        <div>
          <div><b>Step 1:</b> Create a new parameter group</div>
          <Spacer/>
          <p>
            To set parameter group parameter group for your instance,
            <Link href={"https://docs.aws.amazon.com/AmazonRDS/latest/UserGuide/USER_WorkingWithDBInstanceParamGroups.html#USER_WorkingWithParamGroups.Creating"}> create a custom DB parameter group</Link> and then associate it with your instance.
            Custom parameters cannot be set on the default RDS parameter groups.
          </p>
          <Spacer/>
          <Image
              src={mysql_rds1}
              width={"600px"}
          />

        </div>
        <div style={{marginTop: "30px"}}>
          <b>Step 2:</b> Edit the custom parameter group, and set:
          <div>binlog_format -{'>'} "ROW"</div>
          <div>log_bin_trust_function_creators -{'>'} 1</div>

          <Spacer />
          <Image src={mysql_rds2} width={"600px"}/>
        </div>
        <div style={{marginTop: "30px"}}>
          <b>Step 3:</b> Update your RDS instance to <Link
            href="https://docs.aws.amazon.com/AmazonRDS/latest/UserGuide/USER_WorkingWithDBInstanceParamGroups.html#USER_WorkingWithParamGroups.Associating">use the new parameter group</Link>
          <Spacer />
          <Image src={mysql_rds4} width={"600px"}/>
          <Spacer />
          <p>Make sure you choose "Apply Immediately" to apply the changes immediately.</p>
          <Spacer />
          <Image src={mysql_rds5} width={"600px"}/>
        </div>
        <div style={{marginTop: "30px"}}>
          <b>Step 4:</b> Restart your RDS instance for the changes to take affect.
          <p>You'll know that the changes have taken affect when the status of your RDS Instance Parameter Group changes to "In Sync".</p>
          <Spacer />
          <Image src={mysql_rds6} width={"400px"}/>
        </div>
        <div style={{marginTop: "30px"}}>
          <b>Step 5:</b>Verify that all the new configurations are enabled by running the following commands:
          <Spacer />
          <CopyBlock theme={CodeTheme} language="sql" text={"-- Required configuration: ON\n" +
              "SHOW VARIABLES LIKE 'log_bin';\n" +
              "\n" +
              "-- Required configuration: ON\n" +
              "SHOW VARIABLES LIKE 'log_bin_trust_function_creators';\n" +
              "\n" +
              "-- Required configuration: ROW\n" +
              "SHOW VARIABLES LIKE 'binlog_format';"} showLineNumbers={false}
                     codeBlock/>
          <Spacer/>
        </div>
        <Spacer/>
        <div><b>Configure Retention Policy</b></div>
        <Spacer/>
        <div>
          <div><b>Step 1:</b> Edit the RDS retention policy:</div>
          <Spacer/>
          <CopyBlock theme={CodeTheme} language="sql" text={"CALL mysql.rds_set_configuration('binlog retention hours', 3);"} showLineNumbers={false}
                     codeBlock/>
        </div>
        <div >
          <b>Step 2:</b> Verify that the retention policy is set correctly:
          <Spacer/>
          <CopyBlock theme={CodeTheme} language="sql" text={"CALL mysql.rds_show_configuration();\n"} showLineNumbers={false}
                     codeBlock/>
        </div>
      </div>
  )
}

const GcpCloudSqlInstructions = () => <div>
  <div>
    <div>To check if your Postgres is configured for logical replication, you can run the following command:</div>
    <Spacer />
    <CopyBlock theme={CodeTheme} language="sql" text={"SHOW wal_level;"} showLineNumbers={false} codeBlock/>
    <Spacer />
    <div>
      If the result is anything other than `logical`, it means that logical replication is not enabled. Follow the steps
      in <Link href={"https://docs.epsio.io/getting-started/deployment/GCP/deployment-guide-GCP/#32-enable-logical-replication"}>Enable logical replication in GCP Cloud SQL</Link> to enable logical replication.
    </div>
    <Spacer />
  </div>
</div>


const SelfHostedInstructions = () => <div>
  <div>
    <div>To check if your MySQL instance is already configured to work with Epsio, you can run the following commands:</div>
    <Spacer />
    <CopyBlock theme={CodeTheme} language="sql" text={"-- Required configuration: ON\n" +
        "SHOW VARIABLES LIKE 'log_bin';\n" +
        "\n" +
        "-- Required configuration: ROW\n" +
        "SHOW VARIABLES LIKE 'binlog_format';\n" +
        "\n" +
        "-- Required configuration: ON\n" +
        "SHOW VARIABLES LIKE 'log_bin_trust_function_creators';"} showLineNumbers={false} codeBlock/>
    <Spacer />
    <div>
      If any of the above do not show the required configuration, follow the following steps:
    </div>
    <Spacer />
    <div>
      <b>Step 1:</b> Enable binlog (only if log_bin is OFF or binlog format is not ROW)
      <p>
        Edit your mysql conf file and add the following configurations:
      </p>
      <Spacer />
      <CopyBlock theme={CodeTheme} language="" text={"server-id = 223344\n" +
          "log_bin = mysql-bin\n" +
          "binlog_format = ROW\n" +
          "binlog_row_image = FULL\n" +
          "binlog_expire_logs_seconds  = 864000"} showLineNumbers={false}
                 codeBlock/>
    </div>
    <p>For the above changes to take effect, you must restart your mysql server.</p>
  </div>
  <div style={{marginTop: "30px"}}>
    <b>Step 2:</b> Enable log_bin_trust_function_creators.
      <Spacer />
      <CopyBlock theme={CodeTheme} language="" text={"SET PERSIST log_bin_trust_function_creators = 1;"} showLineNumbers={false}
                 codeBlock/>
  </div>
  <div style={{marginTop: "30px"}}>
    <b>Step 3:</b> Verify that all the new configurations are enabled by running the following commands:
    <Spacer />
    <CopyBlock theme={CodeTheme} language="sql" text={"-- Required configuration: ON\n" +
        "SHOW VARIABLES LIKE 'log_bin';\n" +
        "\n" +
        "-- Required configuration: ROW\n" +
        "SHOW VARIABLES LIKE 'binlog_format';\n" +
        "\n" +
        "-- Required configuration: ON\n" +
        "SHOW VARIABLES LIKE 'log_bin_trust_function_creators';"} showLineNumbers={false} codeBlock/>
    <Spacer />
  </div>
</div>


const ConfigureReplication = ({deployment, updateDeployment, reloadAccount}: {deployment: Deployment, reloadAccount: any, updateDeployment: (newDeployment: Deployment) => Promise<void>}) => {

  const [verifyingReplication, setVerifyingReplication] = useState(false);

  const verifyReplication = () => {
    setVerifyingReplication(true);
    updateDeployment({
      ...deployment,
        databaseInitializationSetup: true,
        databaseInitializationError: null,
      replicationError: null,
      replicationSetup: true
    });
  }

  useEffect(() => {
    if (deployment.replicationError || deployment.databaseInitializationError) {
      setVerifyingReplication(false);
    }
  }, [deployment.replicationError, deployment.databaseInitializationError]);

  return (
      <Center>
          <Grid
              marginTop={"20px"}
              h="100%"
              w={"100%"}
              templateColumns='repeat(auto-fit, 1fr)'
              gap={"30px"}
              templateAreas={`
                "ConfigureReplication ValidateConfiguration"
              `}
          >
            <GridItem area={"ValidateConfiguration"} height={'100%'}>
              <Flex direction={"column"} height={'calc(100vh - 350px)'}>
                <Card height={"100%"}>
                    <Tabs style={{height: "100%"}} display={"grid"}>
                      <TabList>
                        <Tab>Self Hosted</Tab>
                        <Tab>AWS RDS</Tab>
                        <Tab>AWS Aurora</Tab>
                      </TabList>

                    <TabPanels style={{overflow: "auto", maxHeight: "100%", height: "100%"}}>
                      <TabPanel>
                        <SelfHostedInstructions/>
                      </TabPanel>
                      <TabPanel>
                        <AWSRDSInstructions />
                      </TabPanel>
                      <TabPanel>
                        <AWSAuroraInstructions />
                      </TabPanel>
                    </TabPanels>
                      <Flex direction={"column"} style={{marginLeft: "1em"}} height={"100%"}>
                        <div style={{marginBottom: "10px", marginTop: "10px", height: "100%"}}>
                          <Button onClick={verifyReplication} isLoading={verifyingReplication} colorScheme={"blue"}
                                  style={{padding: "20px"}} fontSize={"md"}>Validate Configuration</Button>
                        </div>
                        {
                            (verifyingReplication || deployment.databaseInitialized || deployment.databaseInitializationError) &&
                            <StatusLabel waitingText={"Waiting for Epsio to create functions..."}
                                         finishedText={"Finished"}
                                         waiting={!deployment.databaseInitialized}
                                         error={deployment.databaseInitializationError}/>
                        }
                        {
                            (verifyingReplication || deployment.replicationError) && deployment.databaseInitialized &&
                            <StatusLabel waitingText={"Verifying configurations..."}
                                         waiting={!deployment.replicationInitialized}
                                         error={deployment.replicationError}
                                         finishedText={"Epsio is ready to receive replication!"}
                            />
                        }
                      </Flex>
                    </Tabs>

                </Card>
                <Flex align={"flex-end"} justify={"flex-end"} style={{ height: "100%" }}></Flex>
                <div style={{position: "fixed", right: "55px", zIndex: 999, bottom: 10}}>
                  <DeleteDeploymentButton variant={"simple"} deployment={deployment} reloadAccount={reloadAccount}/>
                </div>
              </Flex>
            </GridItem>
          </Grid>

      </Center>
  )
};

export default ConfigureReplication;