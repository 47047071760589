import InstallScript from "./InstallScript";
import DeployEpsioAWS from "./DeployEpsioAWS";
import DeployEpsioRaw from "./DeployEpsioRaw";
import ConfigureReplication from "./ConfigureReplication";
import {github} from "react-code-blocks";
import DeployEpsioTF from "./DeployEpsioTF";


export const createSteps = (deployment) => {

  let firstStep;

  switch (deployment.deploymentType) {
    case "RAW":
      firstStep = {
        label: "Deploy Epsio",
        header: "Welcome to Epsio!",
        description: "To get Epsio running, we need to run a one-liner on your machine. This will install Epsio and notify Epsio Cloud that it has been installed successfully.",
        component: DeployEpsioRaw
      };
      break;
    case "TERRAFORM":
      firstStep = {
        label: "Deploy Epsio",
        header: "Welcome to Epsio!",
        description: "Wait for the Terraform deployment to complete and notify Epsio Cloud that it has been installed successfully.",
        component: DeployEpsioTF
      };
      break;
    case "AWS":
    default:
      firstStep = {
        label: "Deploy Epsio",
        header: "Welcome to Epsio!",
        description: "To get Epsio running, we need to run a CloudFormation template which will create a IAM role that Epsio Cloud can use to manage your Epsio deployment. The IAM role will be able to create Epsio instances, but will not have access to any other resources in your account.",
        component: DeployEpsioAWS
      };
      break;
  }

  let secondStep = {
    label: "Prepare your database",
    header: "Next, we need to run some basic commands on your database",
    description: "These commands will create the basic entities allowing Epsio to run- creating a epsio_user " +
        "that will be able to create a schema in which Epsio's functions will be stored. " +
        "This will not allow Epsio to change/update any data in your database.",
    component: InstallScript
  };

  let thirdStep = {
    label: "Configure Replication",
    header: "",
    description: "",
    component: ConfigureReplication
  }
  return [firstStep,
  secondStep,
  thirdStep];
}


export const CodeTheme = {
  ...github,
  keywordColor: "var(--chakra-colors-blue-500)",
  backgroundColor: "var(--chakra-colors-gray-50)"
}