import {HStack, VStack, Flex, Center, Button, Text, Link, useColorModeValue, Heading} from "@chakra-ui/react"
import {HiChip} from "react-icons/hi";
import {FaMemory} from "react-icons/fa";
import React, {useCallback, useEffect, useState} from "react";
import {ClipLoader, HashLoader, PuffLoader} from "react-spinners";
import StatusLabel from "./StatusLabel";
import {Deployment} from "../../../../../types";
import useInterval from "../../../../../hooks/useInterval";
import DeleteDeploymentButton from "../../DeleteDeploymentButton";


const InstanceCard = ({title, cpus, memory, description, selected, onClick, disabled}: {
    title: string,
    cpus: number,
    memory: number,
    description: string,
    selected: boolean,
    onClick: () => void,
    disabled?: boolean
}) => {
    const strongColor = "gray.800"; // useColorModeValue("gray.800", "white");
    const mediumColor = selected ? "white" : "gray.600"; // useColorModeValue("gray.600", "white");
    const weakColor = selected ? "white" : (disabled ? "gray.300" : "teal.300"); //useColorModeValue("teal.300", "white");

    const backgroundColor = selected ? (disabled ? "var(--chakra-colors-gray-400)" : "rgb(88, 210, 199)") : "rgba(255, 255, 255, 0.6)";

    return (
        <div style={{width: "300px"}}>
            <Center>
                <VStack style={{
                    borderRadius: "8px",
                    borderWidth: "1px",
                    padding: "15px 30px",
                    height: "180px",
                    width: "210px",
                    backgroundColor
                }} role={disabled ? "" : "button"} onClick={disabled ? () => {
                } : onClick}>
                    <HStack justify={"center"} style={{"width": "100%"}}>
                        <Center style={{width: "70px"}}>
                            <Text style={{marginLeft: "1px"}} fontSize={"5xl"} fontWeight={"bold"}
                                  color={mediumColor}>{cpus}</Text>
                        </Center>
                        <div>
                            <Center>
                                <Text fontWeight={"bold"} color={mediumColor}>CPUs</Text>
                            </Center>
                            <Center>
                                <Text color={weakColor}>
                                    <HiChip size={30}/>
                                </Text>
                            </Center>
                        </div>
                    </HStack>

                    <HStack justify={"center"} style={{"width": "100%"}}>
                        <Center style={{width: "70px"}}>
                            <Text style={{marginRight: "5px"}} fontSize={"4xl"} color={mediumColor}>{memory}</Text>
                        </Center>
                        <div>
                            <Center><Text fontWeight={"bold"} color={mediumColor}>GB</Text></Center>
                            <Center>
                                <Text color={weakColor}><FaMemory size={30}/></Text>
                            </Center>
                        </div>
                    </HStack>
                </VStack>
            </Center>
            <Center style={{marginTop: "10px"}}>
                <Text fontWeight={"bold"} color={strongColor} fontSize={20}>{title}</Text>
            </Center>
            <Center>
                <Text color={"gray.400"} textAlign={"center"} fontSize={15}>{description}</Text>
            </Center>
        </div>
    )
}


/**
 * If we have a VPC ID, we have received a IAM role and can deploy the instance.
 */
const isDeploying = (deployment: Deployment) => deployment.vpcId !== null;

const randomStackName = () => `epsio-${Math.random().toString(36).substring(7)}`


const TIMEOUT_FOR_EPSIO_TO_SAY_HELLO = 60 * 10; // seconds

const Index = ({deployment, updateDeployment, reloadAccount}: {
    deployment: Deployment,
    reloadAccount: any,
    updateDeployment: (newDeployment: Deployment) => Promise<void>
}) => {
    const selectedInstance = deployment.instanceType !== null ? deployment.instanceType : "medium";
    const [didTimeout, setDidTimeout] = useState(false);

    const updateInstance = (newInstance: string) => {
        updateDeployment({...deployment, instanceType: newInstance});
    }

    const deployCloudFormation = () => {
        updateDeployment({...deployment, launchedCloudformation: true}).then(
            () => {
                // This has to be synchronized with rump's consts.py
                const bucketName = "epsio-cloud-formations";
                window.open(
                    `https://console.aws.amazon.com/cloudformation/home#/stacks/quickcreate?templateURL=https://${bucketName}.s3.amazonaws.com/${deployment.id}.yml&stackName=${randomStackName()}`
                )
            }
        )
    }

    useInterval(() => {
        if (deployment.timeInstanceLaunched !== null && !deployment.epsioSaidHello) {
            const seconds = Math.floor((new Date().getTime() / 1000 - deployment.timeInstanceLaunched));
            if (seconds > TIMEOUT_FOR_EPSIO_TO_SAY_HELLO && !didTimeout) {
                setDidTimeout(true);
            }
        }
    }, 5000);

    const rightBrace = "}";

    const styles = {
        surroundingTrueCenter: {
            flexGrow: 1,
            flexBasis: 0,
            overflow: "auto"
        }
    }

    return (
        <div style={{width: "100%", height: "100%"}}>
            <Flex style={{height: "100%"}} flexDirection={"column"} justifyContent={"space-evenly"}>
                <Flex justifyContent={"center"}>
                    <HStack spacing={4} style={{paddingBottom: "5px"}} justify={"space-evenly"}>
                        <InstanceCard disabled={isDeploying(deployment)} onClick={() => updateInstance("starter")}
                                      selected={selectedInstance === "starter"}
                                      title={"Lightweight"} cpus={8} memory={32}
                                      description={"Typically good for databases with small to medium workloads"}/>
                        <InstanceCard disabled={isDeploying(deployment)} onClick={() => updateInstance("medium")}
                                      selected={selectedInstance === "medium"}
                                      title={"Medium"} cpus={16} memory={64}
                                      description={"Typically good for databases with medium workloads"}/>
                        <InstanceCard disabled={isDeploying(deployment)} onClick={() => updateInstance("power")}
                                      selected={selectedInstance === "power"}
                                      title={"Power"} cpus={32} memory={128}
                                      description={"Typically good for databases with medium to large workloads"}/>
                    </HStack>
                </Flex>

                <Flex justifyContent={"space-between"}>
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center", ...styles.surroundingTrueCenter,
                        height: "200px"
                    }}>
                        <div style={{display: "flex", justifyContent: "end"}}>
                            <Text style={{width: "313px"}} color={"gray.400"}>When the CloudFormation wizard is launched
                                within
                                AWS, select the VPC and Subnet you'd like to deploy Epsio within, and then click
                                `Submit`,
                                leaving the default options. When launched, come back here to continue.</Text>
                        </div>
                    </div>
                    <div>
                        <Text color={"gray.400"} fontSize={"140pt"} fontWeight={"light"}
                              style={{opacity: "0.4", lineHeight: 1}}>{rightBrace}</Text>
                    </div>
                    <div style={{paddingTop: "40px", ...styles.surroundingTrueCenter, overflowY: "visible"}}>

                        <div style={{marginLeft: "20px", marginBottom: "10px", marginTop: "38px", display: "flex"}}>
                            <div><Button isLoading={isDeploying(deployment)} onClick={deployCloudFormation}
                                         colorScheme={"blue"}
                                         style={{flex: 1, padding: "25px", marginRight: "5px", width: "243px"}}
                                         fontSize={"lg"}>Launch CloudFormation</Button>

                                <div style={{marginTop: "10px", marginLeft: "15px"}}>
                                    <StatusLabel waitingText={"Waiting for IAM Role..."}
                                                 finishedText={"Received IAM Role"}
                                                 waiting={deployment.vpcId === null} error={null}/>
                                    {deployment.vpcId && <StatusLabel waitingText={"Creating Epsio instance..."}
                                                                      finishedText={"Created Epsio instance"}
                                                                      waiting={deployment.instanceId === null}
                                                                      error={null}
                                    />}
                                    {deployment.instanceId &&
                                        <StatusLabel waitingText={"Waiting for Epsio to say hello..."}
                                                     finishedText={"Epsio said hello! Finished."}
                                                     error={didTimeout ?
                                                         <div>Epsio isn't reaching out.<br/>Try checking for <Link
                                                             style={{textDecoration: "underline"}} color={"red.400"}
                                                             href={"https://docs.epsio.io/getting-started/deployment/networking/#troubleshooting"}
                                                             isExternal>network connectivity issues.</Link>
                                                         </div> : null}
                                                     timeout={didTimeout}
                                                     waiting={!deployment.epsioSaidHello}/>}
                                </div>
                            </div>
                            <div>
                                <p style={{
                                    display: '-webkit-box',
                                    WebkitBoxOrient: 'vertical',
                                    WebkitLineClamp: 5,
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    marginLeft: "10px",
                                    color: "var(--chakra-colors-red-400)"
                                }}>
                                    {deployment.launchError}
                                </p>
                            </div>
                        </div>

                    </div>
                </Flex>
            </Flex>
            <div style={{position: "fixed", right: "55px", zIndex: 999, bottom: 10}}>
                <DeleteDeploymentButton variant={"simple"} deployment={deployment} reloadAccount={reloadAccount}/>
            </div>
        </div>
    )
}

export default Index;
