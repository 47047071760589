import React from "react";
// Chakra imports
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Image,
  Input,
  Link,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Assets
import negativeLogo from "../../assets/img/negative-logo.svg";
import {useNavigate} from "react-router-dom";
import {post} from "../../client/client";

const ResetPasswordEmailStatus = {
  SENDING: "sending",
  SENT: "sent",
  NOT_SENT: "not_sent",
}


function ForgotPassword() {
  const textColor = useColorModeValue("gray.400", "white");
  const navigate = useNavigate();
  const [email, setEmail] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [resetPasswordEmailStatus, setResetPasswordEmailStatus] = React.useState(ResetPasswordEmailStatus.NOT_SENT);
  const [loading, setLoading] = React.useState(false);
  const [emailInvalid, setEmailInvalid] = React.useState(false);

  const sendPasswordToken = () => {
    let error = "";
    if (email.length < 1) {
      error = "Email cannot be empty";
      setEmailInvalid(true);
    }
    if (error.length > 0) {
      setErrorMessage(error);
      return;
    }

    setLoading(true);
    setErrorMessage(null);

    post("send_password_token", {
      email,
    }).then((response) => {
      setLoading(false);
      setErrorMessage(null);
      setResetPasswordEmailStatus(ResetPasswordEmailStatus.SENT);
    }).catch((error) => {
      setLoading(false);
      setErrorMessage(error.response.data.error);
    });
  }

  const onKeyDown = (event) => {
    if (event.key === "Enter") {
      sendPasswordToken();
    }
  }

  return (
    <Flex position='relative' mb='40px' style={{width: "100%"}}>
      <Flex
        h={{sm: "initial", md: "75vh", lg: "100vh"}}
        w='100%'
        // maxW='1044px'
        mx='auto'
        justifyContent='space-between'
        mb='30px'
        pt={{sm: "100px", md: "0px"}}>

        <Box
          display={{base: "none", md: "block"}}
          overflowX='hidden'
          h='100%'
          w='35%'
          left='0px'>
          <Box
            bg={useColorModeValue("blue.700", "gray.800")}
            w='100%'
            h='100%'
            bgSize='cover'
            borderBottomRightRadius='20px'>
            <Center style={{height: "100%"}}>
              <Flex flexDirection={"column"}>
                <Center>
                  <Image src={negativeLogo} w='100%' h='50%'/>
                </Center>
              </Flex>
            </Center>
          </Box>
        </Box>


        <Flex
          alignItems='center'
          justifyContent='start'
          style={{userSelect: "none", width: "50%"}}
          // w={{ base: "100%", md: "50%", lg: "42%" }}
        >

          <div style={{width: "100%"}}>
            <Flex
              direction='column'
              style={{maxWidth: "600px"}}
              background='transparent'
              p='0px'
              mt={{md: "150px", lg: "80px"}}>
              <Heading color={"blue.700"} fontSize='32px' mb='10px'>
                Reset Your Password
              </Heading>
              {
                resetPasswordEmailStatus === ResetPasswordEmailStatus.SENT ?
                  <Alert status='success'>
                    <AlertIcon/>
                    An email with a confirmation link was sent. Please check your inbox and spam folders.
                  </Alert> :
                  <>
                    <Text
                      mb='36px'
                      ms='4px'
                      color={textColor}
                      fontWeight='bold'
                      fontSize='14px'>
                      Enter your email and we will email you instructions to reset your password.
                    </Text>
                    <FormControl onSubmit={sendPasswordToken}>
                      <FormLabel ms='4px' fontSize='sm' fontWeight='bold' color={"blue.700"}>
                        Email
                      </FormLabel>
                      <Input
                        borderRadius='15px'
                        mb='24px'
                        fontSize='sm'
                        type='text'
                        autocomplete="username"
                        placeholder='Your email address'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        onKeyDown={onKeyDown}
                        size='lg'
                        isInvalid={emailInvalid}
                      />
                      {errorMessage &&
                        <Alert status='error'>
                          <AlertIcon/>
                          {errorMessage}
                        </Alert>
                      }
                      <HStack spacing={6}>
                        <Button
                          onClick={sendPasswordToken}
                          onSubmit={sendPasswordToken}
                          isLoading={loading}
                          fontSize='13pt'
                          type='submit'
                          bg='teal.300'
                          color='white'
                          w='50%'
                          h='45'
                          mb='20px'
                          mt='20px'
                          _hover={{
                            bg: "teal.200",
                          }}
                          _active={{
                            bg: "teal.400",
                          }}>
                          Reset Password
                        </Button>
                        <Link
                          color={"blue.400"}
                          fontSize="12pt"
                          borderBottom="0.8px solid currentColor"
                          onClick={() => navigate("/auth/sign_in", {replace: false})}>
                          Return to login
                        </Link>
                      </HStack>
                    </FormControl>
                  </>
              }
            </Flex>
          </div>
        </Flex>


      </Flex>
    </Flex>
  );
}

export default ForgotPassword;
