// Chakra imports
import {
  Flex, Button, AlertDialog,
  AlertDialogOverlay, AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogCloseButton,
  AlertDialogBody, Alert, AlertIcon,
  Text,
  MenuGroup, MenuItem, Menu, MenuButton, MenuList, MenuDivider, Center
} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import CreateDeployment from "./components/CreateDeployment/postgres";
import {withAccount} from "../../contexts";
import {delete_, get, post, put} from "../../client/client";
import Deployments from "./components/Deployments";
import {useNavigate} from 'react-router-dom';

import piggy from "../../assets/img/pig-mad.png";
import {useSpring, useSpringRef, animated} from '@react-spring/web'

const Dashboard = ({account, reloadAccount}) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [otherDeploymentType, setOtherDeploymentType] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const cancelRef = React.useRef()

  const isFirstDeployment = account?.deployments?.length === 0;

  useEffect(() => {
    reloadAccount();
  }, []);

  const onClose = () => setIsOpen(false);

  const createPostgresDeployment = () => {
    navigate(`/admin/deployment/postgres`);
  };

  const createMySQLDeployment = () => {
    navigate(`/admin/deployment/mysql`);
  };


  const createMSSQLDeployment = () => {
    navigate(`/admin/deployment/mssql`);
  };

  const createOtherDeployment = (type) => {
    post('other_deployment', {type});
    setOtherDeploymentType(type);
    setIsOpen(true);
  };

  const requestToJoin = () => {
    post('request_other_deployment', {type: otherDeploymentType});
    setShowSuccess(true);
    setIsOpen(false);
  }

  return (
      <Flex direction='column' maxWidth={"90%"} margin={"auto"}
            style={{height: "100%", zIndex: "50", position: "relative"}}>
        <div style={isFirstDeployment ? {display: "flex", justifyContent: "center", height: "calc(100% - 200px)"} : {}}>
        {
          <div style={isFirstDeployment ? {display: "flex", justifyContent: "center", flexDirection: "column"} : {}}>
            {
              isFirstDeployment ?
                   <div style={{marginTop: "20px"}}>
                    <Text style={{marginTop: "20px"}} fontSize={"3xl"} fontWeight={"bold"}
                                  textAlign={"center"}>Welcome to Epsio!</Text>
                    <Text fontSize={"md"} color={"gray.500"} textAlign={"center"} >Click below to get started with your first deployment</Text>
              </div>:
              <Deployments
                  title={"Deployments"}
                  style={{ height: "100%"}}
                  data={account?.deployments || []}
                  reloadAccount={reloadAccount}
                  account={account}
              />
            }
            {showSuccess && <div style={{marginTop: "8px", width: "fit-content"}}>
              <Alert status='success' variant='subtle'>
                <AlertIcon/>
                Successfully requested to create {otherDeploymentType} deployment.
              </Alert>
            </div>}
            <div style={{marginTop: "10px", ...(isFirstDeployment ? {display: "flex", justifyContent: "center", marginTop: "20px"} : {})}}>
              <Menu>
                <MenuButton as={Button} colorScheme='teal'>
                  Create Deployment
                </MenuButton>
                <MenuList>
                  <MenuGroup title='Database Type'>
                  </MenuGroup>
                    <MenuItem onClick={createPostgresDeployment}>Postgres</MenuItem>
                    <MenuItem onClick={createMySQLDeployment}>MySQL</MenuItem>
                    <MenuItem onClick={createMSSQLDeployment}>SQL Server</MenuItem>
                    <MenuItem onClick={() => createOtherDeployment("BigQuery")}>BigQuery</MenuItem>
                    <MenuItem onClick={() => createOtherDeployment("Snowflake")}>Snowflake</MenuItem>
                </MenuList>
              </Menu>
            </div>
            <AlertDialog
                motionPreset='slideInBottom'
                onClose={onClose}
                isOpen={isOpen}
                isCentered
            >
             <AlertDialogOverlay/>

              <AlertDialogContent>
                <AlertDialogHeader>{otherDeploymentType} Deployment</AlertDialogHeader>
                <AlertDialogCloseButton/>
                <AlertDialogBody>
                  {otherDeploymentType} is currently in closed beta. To request to join, please click to request
                  and we'll get back to you as soon as possible.
                </AlertDialogBody>
                <AlertDialogFooter>
                  <Button ref={cancelRef} onClick={onClose}>
                    Cancel
                  </Button>
                  <Button colorScheme='green' ml={3} onClick={requestToJoin}>
                    Request to Join
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>
          </div>
        }
        </div>
      </Flex>
  );
}

export default withAccount(Dashboard);
