import React from 'react';
import {Center, Flex, Text} from "@chakra-ui/react";
import {PuffLoader} from "react-spinners";
import {BsCheckLg} from "react-icons/bs";
import {CloseIcon} from "@chakra-ui/icons";


const StatusLabel = ({waitingText, finishedText, waiting, error, timeout}: {waitingText: string, finishedText: string, waiting: boolean, error: null | JSX.Element | string, timeout?: boolean}) => {
  return (
      <Flex>
        {
          error && !timeout ?
              <CloseIcon style={{marginTop: "5px"}} width="30px" color={"red.400"}/> :
          waiting ? <PuffLoader
              color={timeout ? "var(--chakra-colors-red-500)" : "#36D7B7"}
              loading={true}
              size={30}
              aria-label="Loading Spinner"
              data-testid="loader"
          /> : <BsCheckLg opacity={0.4} size={30} color={"#36D7B7"}/>
        }
        <Center style={{marginLeft: "5px"}}>
          <div>
          <Text color={error ? "red.400" : "gray.400"} fontSize={"md"}>{waiting ? waitingText : finishedText}</Text>
          {
            error ? <Text style={{whiteSpace: "pre-line"}} color={"red.400"} fontSize={"sm"}>{error}</Text> : null
          }
          </div>
        </Center>
    </Flex>
  )
}

export default StatusLabel;