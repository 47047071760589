import React, {useState} from "react";
// Chakra imports
import {
  Box,
  Flex,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Switch,
  Text,
  Center,
  Select,
  Image,
  useColorModeValue,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";
// Assets
import negativeLogo from "../../assets/img/negative-logo.svg";
import {useNavigate, useSearchParams} from "react-router-dom";
import {post, get} from "../../client/client";


function SignUp() {
  const textColor = useColorModeValue("gray.400", "white");
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [password, setPassword] = React.useState("");
  const [error, setError] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [passwordInvalid, setPasswordInvalid] = React.useState(false);
  const [email, setEmail] = useState(searchParams.get("email"));

  const emailDisabled = searchParams.has("email");

  const register = () => {
    if (password.length < 8) {
      setError("Password must be at least 8 characters");
      setPasswordInvalid(true);
      return;
    }
    if (email.length < 1) {
      setError("Email must not be empty");
      return;
    }

    setLoading(true);
    setError("");

    post("register",{
      email,
      id: searchParams.get("id"),
      password,
    }).then((response) => {
      setLoading(false);
      if (searchParams.has("id")) {
        navigate("/admin/dashboard", {replace: true});
      } else {
        navigate("/auth/confirm_email_prompt", { replace: true });
      }
    }).catch((error) => {
      setLoading(false);
      setError(error.response.data.error);
    });
  }

  const onKeyDown = (event) => {
    if (event.key === "Enter") {
      register();
    }
  }

  return (
      <Flex position='relative' mb='40px' style={{width: "100%", height: "100vh", overflowY: "scroll"}}>
        <Flex
            h={{ sm: "initial", md: "50vh", lg: "100vh" }}
            w='100%'
            mx='auto'
            justifyContent='space-between'
            mb='30px'
            pt={{ sm: "100px", md: "0px" }}>

          <Box
              display={{ base: "none", md: "block" }}
              overflowX='hidden'
              h='100%'
              w='65%'
              left='0px'>
            <Box
                bg={useColorModeValue("blue.700", "gray.800")}
                w='100%'
                h='100%'
                bgSize='cover'
                borderBottomRightRadius='20px'>
              <Center style={{height: "100%"}}>
                <Flex flexDirection={"column"}>
                  <Center>
                    <Image src={negativeLogo} w='80%' h='50%' />
                  </Center>
                  <div style={{marginTop: "20px"}}>
                    <Center>
                      <Text color={"gray.100"} fontSize={"2xl"} fontWeight={"bold"} >Welcome to Epsio!</Text>
                    </Center>
                    <Center>
                      <Text color={"gray.300"} fontSize={"lg"}>Please sign up to begin setting up your first deployment.</Text>
                    </Center>
                  </div>
                </Flex>
              </Center>
            </Box>
          </Box>


          <Flex
              alignItems='center'
              justifyContent='start'
              style={{ userSelect: "none", left: "75%", width: "50%" }}
          >

            <div style={{width: "100%", marginLeft: "100px"}}>

              <Flex
                  direction='column'
                  w='100%'
                  style={{maxWidth: "600px"}}
                  background='transparent'
                  p='0px'
                  mt={{ md: "150px", lg: "80px" }}>
                <Heading color={"blue.700"} fontSize='32px' mb='10px'>
                  Welcome
                </Heading>
                <Text
                    mb='36px'
                    ms='4px'
                    color={textColor}
                    fontWeight='bold'
                    fontSize='14px'>
                  Enter your email and password to sign up
                </Text>
                <FormControl>
                  <FormLabel ms='4px' fontSize='sm' fontWeight='bold' color={"blue.700"}>
                    Email
                  </FormLabel>
                  <Input
                      disabled={emailDisabled}
                      borderRadius='15px'
                      mb='24px'
                      fontSize='sm'
                      type='text'
                      placeholder='Your email address'
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      autocomplete="username"
                      size='lg'
                  />
                  <FormLabel ms='4px' fontSize='sm' fontWeight='bold' color={"blue.700"}>
                    Password
                  </FormLabel>
                  <Input
                      borderRadius='15px'
                      mb='36px'
                      fontSize='sm'
                      type='password'
                      placeholder='Your password'
                      size='lg'
                      onChange={(e) => setPassword(e.target.value)}
                      isInvalid={passwordInvalid}
                      onKeyDown={onKeyDown}
                      autocomplete="new-password"
                      value={password}
                  />
                  { error &&
                    <Alert status='error'>
                      <AlertIcon />
                      {error}
                    </Alert>
                  }
                  <Button
                      onClick={register}
                      isLoading={loading}
                      fontSize='13pt'
                      type='submit'
                      bg='teal.300'
                      color='white'
                      w='50%'
                      minWidth='200px'
                      h='45'
                      mb='20px'
                      mt='20px'
                      _hover={{
                        bg: "teal.200",
                      }}
                      _active={{
                        bg: "teal.400",
                      }}>
                    Sign Up
                  </Button>
                  <div>
                    <Text color={"gray.400"} fontSize={"sm"}>
                      By signing up, you agree to our <Link color={"blue.400"} href={"https://epsio-resources.storage.googleapis.com/terms_of_use.pdf"} isExternal>
                      Terms of Use</Link> and <Link color={"blue.400"} href={"https://epsio-resources.storage.googleapis.com/privacy_policy.pdf"} isExternal>Privacy Policy</Link>.
                    </Text>
                  </div>
                </FormControl>
              </Flex>
            </div>
          </Flex>
        </Flex>
      </Flex>
  );
}

export default SignUp;
