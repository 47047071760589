// chakra imports
import { Box, ChakraProvider, Portal } from '@chakra-ui/react';
// core components
import React, {useEffect} from 'react';
import {Outlet, Route, Routes, useNavigate} from 'react-router-dom';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import theme from '../theme/theme.js';
import useAccount from "../hooks/useAccount";

export default function Pages(props) {
	const { ...rest } = props;
	// ref for the wrapper div
	const {account} = useAccount();
	const navigate = useNavigate();

	useEffect(() => {
		if (account !== null && account.isEmailConfirmed) {
			navigate('/admin/dashboard', {replace: true});
		}
	}, [account]);
	React.useEffect(() => {
		document.body.style.overflow = 'hidden';
		// Specify how to clean up after this effect:
		return function cleanup() {};
	});

	return (
		<ChakraProvider theme={theme} resetCss={false} w='100%'>
			<Outlet />
		</ChakraProvider>
	);
}
