import React, {useEffect, useState} from 'react';
import {useStripe, useElements, CardElement, AddressElement} from '@stripe/react-stripe-js';
import "./Styles.css";
import CardSection from './CardSection';
import {FormControl, FormLabel, Input, Flex, Button, Alert, AlertIcon, Text, Link} from "@chakra-ui/react";
import {post} from "../../client/client";


const CARD_ELEMENT_OPTIONS = {
  hidePostalCode: true,
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};


const CardSetupForm = ({account, updateAccount, reloadAccount}) => {
  const stripe = useStripe();
  const elements = useElements();

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    setLoading(true);

    const response = await post(`payment_intent`);
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      setLoading(false);
      return;
    }

    const address = await elements.getElement(AddressElement).getValue();
    if (!address.complete) {
      setError("Please enter your billing address");
      setLoading(false);
      return;
    }


    const result = await stripe.confirmCardSetup(response.data.clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: address.value,
      }
    });

    setLoading(false);
    if (result.error) {
      // Display result.error.message in your UI.
      setError(result.error.message)
    } else {
      updateAccount({
        ...account,
        cardSetup: true
      }).then(() => {
        reloadAccount();
      });
    }
  };

  return (
    <div>
      <FormControl>
        <FormLabel>Card Details</FormLabel>
        <CardElement options={CARD_ELEMENT_OPTIONS} />
      </FormControl>

      <FormControl>
        <FormLabel>Billing Details</FormLabel>
        <AddressElement options={{mode: 'billing'}} />
      </FormControl>

      {
        error && <Alert status="error" style={{marginTop: "30px"}}>
          <AlertIcon />
          {error}
        </Alert>
      }
      <div style={{marginTop: "30px"}}>
        <Button onClick={handleSubmit} colorScheme="blue" isLoading={loading}>Confirm Payment Method</Button>
      </div>
      <div style={{marginTop: "10px"}}>
        <Text color={"gray.400"} fontSize={"sm"}>
          By confirming your payment details, you agree to our <Link
            color={"blue.400"} href={"https://epsio-resources.storage.googleapis.com/epsio_saas_agreement.pdf"} isExternal>
          Terms of Use</Link>.
        </Text>
      </div>
    </div>
  );
}
export default CardSetupForm;
