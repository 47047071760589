import {createContext} from "react";
import {Account} from "./types";

export type AccountContextType = {account: Account, updateAccount: (account: Account) => Promise<void>, reloadAccount: () => Promise<void>};
export type NullableAccountContextType = {account: Account | null, updateAccount: (account: Account) => Promise<void>, reloadAccount: () => Promise<void>};
export const AccountContext = createContext<NullableAccountContextType | null>(null);


export const withAccount = (Component: any) => {
  return (props: any) => {
    return  <AccountContext.Consumer>
      {(ctx) => ctx === null || ctx.account === null ? <div></div> : <Component {...props} account={ctx.account} updateAccount={ctx.updateAccount} reloadAccount={ctx.reloadAccount}/>}
      </AccountContext.Consumer>
  }
}
