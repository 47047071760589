import { WarningIcon } from "@chakra-ui/icons";
import { Button, Flex, Link, Text } from "@chakra-ui/react";
import React from "react";
import useAccount from "../../hooks/useAccount";
import moment from "moment";
import {useNavigate} from "react-router-dom";

export function SidebarTrial(props) {
    const navigate = useNavigate();
    const { children, ...rest } = props;
    const {account} = useAccount();
    if (!account || !account.trialExpirationDate || account.cardSetup) {
        return null;
    }
    return (
        <Flex
            borderRadius="15px"
            flexDirection="column"
            bg="blue.700"
            justifyContent="center"
            alignItems="center"
            boxSize="border-box"
            p="15px"
            w="100%"
        >
            <Text fontSize="sm" color="white" fontWeight="bold">
                <WarningIcon color="red.300" /> Trial Account
            </Text>
            <Text fontSize="sm" color="white" fontWeight="bold">
                { moment(account.trialExpirationDate).diff(moment(), 'days') > 0 ?
                    'Expires in ' + moment(account.trialExpirationDate).diff(moment(), 'days') + ' days' :
                    'Expired'
                }
            </Text>
            <Button
                onClick={() => navigate("/admin/settings", {replace: false})}
                fontSize="12px"
                fontWeight="bold"
                w="65%"
                bg="white"
                _hover="none"
                color="black"
                mt="10px"
                height="35px"
            >
                Upgrade Now
            </Button>
        </Flex>
    );
}
