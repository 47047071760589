// Chakra Icons
import { BellIcon, SearchIcon } from "@chakra-ui/icons";
// Chakra Imports
import {
  Button,
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import SidebarResponsive from "../Sidebar/SidebarResponsive";
import {MdLogout} from "react-icons/md";
import {NavLink, useNavigate} from "react-router-dom";
import routes from "../../routes";
import {post} from "../../client/client";


export default function HeaderLinks(props) {
  const { variant, children, fixed, secondary, onOpen, ...rest } = props;
  const navigate = useNavigate();

  // Chakra Color Mode
  let navbarIcon = useColorModeValue("gray.500", "gray.200");

  const logOut = () => {
    post("/sign_out", {}).then((res) => {
      navigate("/auth/sign_in", {replace: true});
    });
  }

  if (secondary) {
    navbarIcon = "white";
  }
  return (
    <Flex
      pe={{ sm: "0px", md: "16px" }}
      w={{ sm: "100%", md: "auto" }}
      alignItems="center"
      flexDirection="row"
    >

      <Button
          ms="0px"
          px="0px"
          me={{ sm: "2px", md: "16px" }}
          color={navbarIcon}
          onClick={logOut}
          variant="transparent-with-icon"
          leftIcon={
              <MdLogout style={{width: "20px", height: "20px", marginRight: "5px"}} color={navbarIcon}  />
          }
        >
          <Text display={{ sm: "none", md: "flex" }}>Sign Out</Text>
      </Button>
      <SidebarResponsive
        logoText={props.logoText}
        secondary={props.secondary}
        routes={routes}
        // logo={logo}
        {...rest}
      />

    </Flex>
  );
}

// HeaderLinks.propTypes = {
//   variant: PropTypes.string,
//   fixed: PropTypes.bool,
//   secondary: PropTypes.bool,
//   onOpen: PropTypes.func,
// };
