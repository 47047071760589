import React from "react";
import {PuffLoader} from "react-spinners"
// Chakra imports
import {
  Box,
  Flex,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Switch,
  Text,
  Center,
  Image,
  useColorModeValue,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";
// Assets
import negativeLogo from "../../assets/img/negative-logo.svg";
import {useNavigate, useSearchParams} from "react-router-dom";
import {post, get} from "../../client/client";
import Card from "../../components/Card/Card";
import useAccount from "../../hooks/useAccount";

const EmailConfirmationSendStatus = {
  SENDING: "sending",
  SENT: "sent",
  NOT_SENT: "not_sent",
}

function SignIn() {
  const textColor = useColorModeValue("gray.400", "white");
  const navigate = useNavigate();
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [emailConfirmationSendStatus, setEmailConfirmationSendStatus] = React.useState(EmailConfirmationSendStatus.NOT_SENT);
  const [loading, setLoading] = React.useState(false);
  const [emailInvalid, setEmailInvalid] = React.useState(false);
  const [passwordInvalid, setPasswordInvalid] = React.useState(false);

  const signIn = () => {
    setEmailConfirmationSendStatus(EmailConfirmationSendStatus.NOT_SENT);
    let error = "";
    if (email.length < 1) {
      error = "Email cannot be empty";
      setEmailInvalid(true);
    }
    if (password.length < 1) {
      error += "\nPassword cannot be empty";
      setPasswordInvalid(true);
    }
    if (error.length > 0) {
      setErrorMessage(error);
      return;
    }

    setLoading(true);
    setErrorMessage(null);

    post("sign_in", {
      email,
      password,
    }).then((response) => {
      setLoading(false);
      navigate("/admin/dashboard", {replace: true});
    }).catch((error) => {
      setLoading(false);
      setErrorMessage(error.response.data.error);
    });
  }

  const onKeyDown = (event) => {
    if (event.key === "Enter") {
      signIn();
    }
  }

  const resendConfirmationEmail = () => {
    setEmailConfirmationSendStatus(EmailConfirmationSendStatus.SENDING);
    post("resend_email_confirmation", {
      email,
    }).then((response) => {
      setErrorMessage(null);
      setEmailConfirmationSendStatus(EmailConfirmationSendStatus.SENT);
    }).catch((error) => {
      setErrorMessage(error.response.data.error);
    });
  }

  return (
    <Flex position='relative' mb='40px' style={{width: "100%"}}>
      <Flex
        h={{sm: "initial", md: "75vh", lg: "100vh"}}
        w='100%'
        // maxW='1044px'
        mx='auto'
        justifyContent='space-between'
        mb='30px'
        pt={{sm: "100px", md: "0px"}}>

        <Box
          display={{base: "none", md: "block"}}
          overflowX='hidden'
          h='100%'
          w='35%'
          left='0px'>
          <Box
            bg={useColorModeValue("blue.700", "gray.800")}
            w='100%'
            h='100%'
            bgSize='cover'
            borderBottomRightRadius='20px'>
            <Center style={{height: "100%"}}>
              <Flex flexDirection={"column"}>
                <Center>
                  <Image src={negativeLogo} w='100%' h='50%'/>
                </Center>
              </Flex>
            </Center>
          </Box>
        </Box>


        <Flex
          alignItems='center'
          justifyContent='start'
          style={{userSelect: "none", width: "50%"}}
          // w={{ base: "100%", md: "50%", lg: "42%" }}
        >

          <div style={{width: "100%"}}>
            <Flex
              direction='column'
              style={{maxWidth: "600px"}}
              background='transparent'
              p='0px'
              mt={{md: "150px", lg: "80px"}}>
              <Heading color={"blue.700"} fontSize='32px' mb='10px'>
                Welcome
              </Heading>
              <Text
                mb='36px'
                ms='4px'
                color={textColor}
                fontWeight='bold'
                fontSize='14px'>
                Enter your email and password to sign in
              </Text>
              <FormControl onSubmit={signIn}>
                <FormLabel ms='4px' fontSize='sm' fontWeight='bold' color={"blue.700"}>
                  Email
                </FormLabel>
                <Input
                  borderRadius='15px'
                  mb='24px'
                  fontSize='sm'
                  type='text'
                  placeholder='Your email address'
                  autocomplete="username"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  onKeyDown={onKeyDown}
                  size='lg'
                  isInvalid={emailInvalid}
                />
                <FormLabel ms='4px' fontSize='sm' fontWeight='bold' color={"blue.700"}>
                  Password
                </FormLabel>
                <Input
                  borderRadius='15px'
                  mb='36px'
                  fontSize='sm'
                  type='password'
                  placeholder='Your password'
                  size='lg'
                  onChange={(e) => setPassword(e.target.value)}
                  onKeyDown={onKeyDown}
                  value={password}
                  autocomplete="current-password"
                  isInvalid={passwordInvalid}
                />
                {errorMessage &&
                  <Alert status='error'>
                    <AlertIcon/>
                    {errorMessage !== "Email not confirmed" ? errorMessage : <Text>
                      Your email is not confirmed yet.<br/>
                      Please check your inbox and spam for a confirmation link.<br/>If you don't see an email from us
                      click on&nbsp;
                      {
                        emailConfirmationSendStatus === EmailConfirmationSendStatus.SENDING &&
                        <PuffLoader
                          size={20}
                          color={"#ffffff"}
                          loading={true}
                          style={{position: "relative", marginLeft: "5px"}}
                        />
                      }
                      {
                        emailConfirmationSendStatus === EmailConfirmationSendStatus.NOT_SENT &&
                        <Link
                          color={"blue.400"}
                          onClick={resendConfirmationEmail}>
                          resend confirmation email
                        </Link>
                      }
                    </Text>}
                  </Alert>
                }
                {
                  emailConfirmationSendStatus === EmailConfirmationSendStatus.SENT &&
                  <Alert status='success'>
                    <AlertIcon/>
                    Email confirmation sent. Please check your inbox for a confirmation link.
                  </Alert>
                }
                <Button
                  onClick={signIn}
                  onSubmit={signIn}
                  isLoading={loading}
                  fontSize='13pt'
                  type='submit'
                  bg='teal.300'
                  color='white'
                  w='50%'
                  h='45'
                  mb='20px'
                  mt='20px'
                  _hover={{
                    bg: "teal.200",
                  }}
                  _active={{
                    bg: "teal.400",
                  }}>
                  Sign In
                </Button>
                <div>
                  <Link
                    color={"blue.400"}
                    onClick={() => navigate("/auth/forgot_password", {replace: false})}>
                    Forgot password?
                  </Link>
                  <Text color={"gray.400"} fontSize={"m"} style={{marginTop: "10px"}}>
                    Don't have an account?&nbsp;
                    <Link
                      color={"blue.400"}
                      onClick={() => navigate("/auth/sign_up", {replace: false})}>
                      Sign Up
                    </Link>
                  </Text>
                </div>
              </FormControl>
            </Flex>
          </div>
        </Flex>


      </Flex>
    </Flex>
  );
}

export default SignIn;
