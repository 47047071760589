import axios from "axios";

const API_URL = "/";

const axiosApi = axios.create({
  baseURL: API_URL,
  withCredentials: true,
});


export const get = async (url, config = {}) => {
  axios.defaults.withCredentials = true;
  return await axiosApi
    .get(url, { ...config, withCredentials: true, headers: {'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json'} })
    .then((response) => response.data);
}


export const post = async (url, data, config = {}) => {
  return axiosApi
    .post(url, { ...data }, { ...config, withCredentials: true })
    .then((response) => response.data);
}


export const put = async (url, data, config = {}) => {
  return axiosApi
    .put(url, { ...data }, { ...config, withCredentials: true })
    .then((response) => response.data);
}


export const delete_ = async (url, data, config = {}) => {
  return axiosApi
    .delete(url, { ...config, withCredentials: true })
    .then((response) => response.data);
}
