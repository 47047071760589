import React, {useEffect, useState} from 'react';
import Card from "../../../../../components/Card/Card";
import CardHeader from "../../../../../components/Card/CardHeader";
import {
  Box,
  Button,
  Center, Flex,
  FormControl,
  FormLabel, Grid, GridItem,
  Heading, Image,
  Input,
  Tab,
  TabList, TabPanel,
  TabPanels, Tabs,
  Text, VStack
} from "@chakra-ui/react";
import {CopyBlock} from "react-code-blocks";
import {BsArrow90DegDown} from "react-icons/bs";
import CardBody from "../../../../../components/Card/CardBody";
import {CodeTheme} from "./consts";
import rdsLogical1 from "../../../../../assets/img/replication/RDS-logical-1.png";
import rdsLogical2 from "../../../../../assets/img/replication/RDS-logical-2.png";
import rdsLogical4 from "../../../../../assets/img/replication/RDS-logical-4.png";
import rdsLogical5 from "../../../../../assets/img/replication/RDS-logical-5.png";
import rdsLogical7 from "../../../../../assets/img/replication/RDS-logical-7.png";
import StatusLabel from "./StatusLabel";
import {Deployment} from "../../../../../types";
import DeleteDeploymentButton from "../../DeleteDeploymentButton";


const Link = ({href, children}: { href: string, children: any }) => <a href={href} target={"_blank"}
                                                                       style={{color: "var(--chakra-colors-blue-500)"}}>{children}</a>

const Spacer = () => <div style={{height: "10px"}}/>

const AWSInstructions = ({databaseName}: {databaseName: string}) => {
  return (
      <div>
        <div>To check if your {databaseName} instance is configured for logical replication, you can run the following command:
        </div>
        <Spacer/>
        <CopyBlock theme={CodeTheme} language="sql" text={"SHOW rds.logical_replication;"} showLineNumbers={false}
                   codeBlock/>
        <Spacer/>
        <div>
          If the result is `off` (or 0), it means that logical replication is not enabled. Follow the steps below to
          enable logical replication.
        </div>
        <Spacer/>
        <Spacer/>
        <div>
          <div><b>Step 1:</b> Create a new parameter group</div>
          <Spacer/>
          <p>
            To set custom parameters for your instance,
            <Link href={"https://docs.aws.amazon.com/AmazonRDS/latest/UserGuide/USER_WorkingWithDBInstanceParamGroups.html#USER_WorkingWithParamGroups.Creating"}> create a custom
              RDS parameter group</Link> and then associate it with your instance.
            Custom parameters cannot be set on the default RDS parameter groups.
          </p>
          <Spacer/>
          <Image
              src={rdsLogical1}
              width={"600px"}
          />

        </div>
        <div style={{marginTop: "30px"}}>
          <b>Step 2:</b> Edit the custom parameter group, and set the <code>logical_replication</code> parameter
          to <code>1</code>
          <Spacer />
          <Image src={rdsLogical2} width={"600px"}/>
        </div>
        <div style={{marginTop: "30px"}}>
          <b>Step 3:</b> Update your {databaseName} instance to <Link
            href="https://docs.aws.amazon.com/AmazonRDS/latest/UserGuide/USER_WorkingWithDBInstanceParamGroups.html#USER_WorkingWithParamGroups.Associating">use
          the new parameter group</Link>
          <Spacer />
          <Image src={rdsLogical4} width={"600px"}/>
          <Spacer />
          <p>Make sure you choose "Apply Immediately" to apply the changes immediately.</p>
          <Spacer />
          <Image src={rdsLogical5} width={"600px"}/>
        </div>
        <div style={{marginTop: "30px"}}>
          <b>Step 4:</b> Restart your RDS instance for the changes to take affect.
          <p>You'll know that the changes have taken affect when the status of your DB instance Parameter Group changes
            to "In Sync".</p>
          <Spacer />
          <Image src={rdsLogical7} width={"400px"}/>
        </div>
        <div style={{marginTop: "30px"}}>
          <b>Step 5:</b> Verify that logical replication is enabled by running the following command:
          <Spacer />
          <CopyBlock theme={CodeTheme} language="sql" text={"SHOW rds.logical_replication;"} showLineNumbers={false}
                     codeBlock/>
          <Spacer/>
          The output should be as follows:
          <Spacer />
          <CopyBlock theme={CodeTheme} language="sql"
                     text={" rds.logical_replication \n ------------------------ \n 1 \n (1 row)"}
                     showLineNumbers={false} codeBlock/>
          (Note that the result could also be <code>on</code> instead of <code>1</code>)
        </div>
      </div>
  )
}

const GcpCloudSqlInstructions = () => <div>
  <div>
    <div>To check if your Postgres is configured for logical replication, you can run the following command:</div>
    <Spacer />
    <CopyBlock theme={CodeTheme} language="sql" text={"SHOW wal_level;"} showLineNumbers={false} codeBlock/>
    <Spacer />
    <div>
      If the result is anything other than `logical`, it means that logical replication is not enabled. Follow the steps
      in <Link href={"https://docs.epsio.io/getting-started/deployment/GCP/deployment-guide-GCP/#32-enable-logical-replication"}>Enable logical replication in GCP Cloud SQL</Link> to enable logical replication.
    </div>
    <Spacer />
  </div>
</div>


const SelfHostedInstructions = () => <div>
  <div>
    <div>To check if your Postgres is configured for logical replication, you can run the following command:</div>
    <Spacer />
    <CopyBlock theme={CodeTheme} language="sql" text={"SHOW wal_level;"} showLineNumbers={false} codeBlock/>
    <Spacer />
    <div>
      If the result is anything other than `logical`, it means that logical replication is not enabled. Follow the steps
      below to enable logical replication.
    </div>
    <Spacer />
    <div>
      <b>Step 1:</b> Change your Postgres <code>wal_level</code> parameter to <code>logical</code>
      <p>
        For standard PostgreSQL installations, you can do this by adding a `wal_level = logical` line to the
        `postgresql.conf` file,
        or running the following command from a super user:
      </p>
      <Spacer />
      <CopyBlock theme={CodeTheme} language="sql" text={"ALTER SYSTEM SET wal_level = logical;"} showLineNumbers={false}
                 codeBlock/>
    </div>
  </div>
  <div style={{marginTop: "30px"}}>
    <b>Step 2:</b> Restart your Postgres instance for the changes to take affect.
  </div>
  <div style={{marginTop: "30px"}}>
    <b>Step 3:</b> Verify that logical replication is enabled by running the following command:
    <Spacer />
    <CopyBlock theme={CodeTheme} language="sql" text={"SHOW wal_level;"} showLineNumbers={false} codeBlock/>
    <Spacer />
    The output should be as follows:
    <Spacer />
    <CopyBlock theme={CodeTheme} language="sql"
               text={" wal_level \n ---------- \n logical \n (1 row)"}
               showLineNumbers={false} codeBlock />
  </div>
</div>

const SelfHostedConfigureReplicationInstructions = () => <div>
  <p>First, create publications for Epsio</p>
  <Spacer />
  <CopyBlock theme={CodeTheme} language="sql" text={"CREATE PUBLICATION epsio_publication;\nCREATE PUBLICATION epsio_command_publication;"} showLineNumbers={false} codeBlock/>
  <Spacer />
  <p>Next, grant `epsio_user` rights to receive replication</p>
  <Spacer />
  <CopyBlock theme={CodeTheme} language="sql" text={"ALTER USER epsio_user WITH REPLICATION;"} showLineNumbers={false} codeBlock/>
</div>

const ConfigureReplication = ({deployment, updateDeployment, reloadAccount}: {deployment: Deployment, reloadAccount: any, updateDeployment: (newDeployment: Deployment) => Promise<void>}) => {

  const [verifyingReplication, setVerifyingReplication] = useState(false);

  const verifyReplication = () => {
    setVerifyingReplication(true);
    updateDeployment({
      ...deployment,
      replicationError: null,
      replicationSetup: true
    });
  }

  useEffect(() => {
    if (deployment.replicationError) {
      setVerifyingReplication(false);
    }
  }, [deployment.replicationError]);
  return (
      <Center>
          <Grid
              marginTop={"20px"}
              autoColumns="1fr"
              h="100%"
              w={"100%"}
              templateColumns='repeat(auto-fit, 1fr)'
              templateRows='1fr'
              gap={"30px"}
              templateAreas={`
                "ConfigureReplication ValidateConfiguration"
              `}
          >
            <GridItem area={"ConfigureReplication"} height={'100%'}>
              <Card height={"100%"}>
                <CardHeader>
                  <Heading size='md'>Configure Logical Replication</Heading>
                </CardHeader>
                <CardBody style={{padding: "5px 0", height: 'calc(100vh - 290px)'}}>
                  <Tabs height={"100%"} display={"grid"}>
                    <TabList>
                      <Tab>Self Hosted</Tab>
                      <Tab>AWS RDS</Tab>
                      <Tab>AWS Aurora</Tab>
                      <Tab>GCP Cloud SQL</Tab>
                    </TabList>

                    <TabPanels style={{overflow: "auto", maxHeight: "100%", height: "100%"}}>
                      <TabPanel>
                        <SelfHostedInstructions/>
                      </TabPanel>
                      <TabPanel>
                        <AWSInstructions databaseName={"RDS"}/>
                      </TabPanel>
                      <TabPanel>
                        <AWSInstructions databaseName={"Aurora"}/>
                      </TabPanel>
                      <TabPanel>
                        <GcpCloudSqlInstructions/>
                      </TabPanel>
                    </TabPanels>
                  </Tabs>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem area={"ValidateConfiguration"} height={'100%'}>
              <Flex direction={"row"}>
                <Box style={{paddingLeft: "20px", paddingBottom: "20px", paddingRight: "10px"}}>
                  <BsArrow90DegDown
                      style={{transform: "scaleX(-1)", height: "90px", width: "90px", marginLeft: "-40px"}}
                      color={"var(--chakra-colors-gray-300"}/>
                </Box>
                <Box>
                  <Text color={"gray.400"} style={{paddingBottom: "10px"}}><b style={{color: "black"}}>
                    Once you are finished setting up logical replication</b>, run the following commands on your database to set up the replication:</Text>
                </Box>
              </Flex>
              <Flex direction={"column"} height={'calc(100vh - 350px)'}>
                <Card height={"100%"}>
                    <Tabs style={{height: "100%"}} display={"grid"}>
                      <TabList>
                        <Tab>Self Hosted</Tab>
                        <Tab>AWS RDS</Tab>
                        <Tab>AWS Aurora</Tab>
                        <Tab>GCP Cloud SQL</Tab>
                      </TabList>

                      <TabPanels style={{height: "100%", overflow: "auto"}}>
                        <TabPanel height={"100%"}>
                          <SelfHostedConfigureReplicationInstructions/>
                        </TabPanel>
                        <TabPanel style={{height: "100%"}}>
                          <p>First, create publications for Epsio</p>
                          <Spacer/>
                          <CopyBlock theme={CodeTheme} language="sql"
                                     text={"CREATE PUBLICATION epsio_publication;\nCREATE PUBLICATION epsio_command_publication;"}
                                     showLineNumbers={false} codeBlock/>
                          <Spacer/>
                          <p>Next, grant `epsio_user` rights to receive replication</p>
                          <Spacer/>
                          <CopyBlock theme={CodeTheme} language="sql" text={"GRANT rds_replication TO epsio_user;"}
                                     showLineNumbers={false} codeBlock/>
                        </TabPanel>
                        <TabPanel style={{height: "100%"}}>
                          <p>First, create publications for Epsio</p>
                          <Spacer/>
                          <CopyBlock theme={CodeTheme} language="sql"
                                     text={"CREATE PUBLICATION epsio_publication;\nCREATE PUBLICATION epsio_command_publication;"}
                                     showLineNumbers={false} codeBlock/>
                          <Spacer/>
                          <p>Next, grant `epsio_user` rights to receive replication</p>
                          <Spacer/>
                          <CopyBlock theme={CodeTheme} language="sql" text={"GRANT rds_replication TO epsio_user;"}
                                     showLineNumbers={false} codeBlock/>
                        </TabPanel>
                        <TabPanel height={"100%"}>
                          <SelfHostedConfigureReplicationInstructions/>
                        </TabPanel>
                      </TabPanels>
                      <Flex direction={"column"} style={{marginLeft: "1em"}} height={"100%"}>
                        <div style={{marginBottom: "10px", height: "100%"}}>
                          <Button onClick={verifyReplication} isLoading={verifyingReplication} colorScheme={"blue"}
                                  style={{padding: "20px"}} fontSize={"md"}>Validate Configuration</Button>
                        </div>
                        {
                            (deployment.replicationError || verifyingReplication) &&
                            <StatusLabel waitingText={"Verifying replication..."}
                                         waiting={!deployment.replicationInitialized}
                                         error={deployment.replicationError}
                                         finishedText={"Epsio is ready to receive replication!"}
                            />
                        }
                      </Flex>
                    </Tabs>

                </Card>
                <Flex align={"flex-end"} justify={"flex-end"} style={{ height: "100%" }}></Flex>
                <div style={{position: "fixed", right: "55px", zIndex: 999, bottom: 10}}>
                  <DeleteDeploymentButton variant={"simple"} deployment={deployment} reloadAccount={reloadAccount}/>
                </div>
              </Flex>
            </GridItem>
          </Grid>

      </Center>
  )
};

export default ConfigureReplication;