import React from "react";
// Chakra imports
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Image,
  Input,
  Link,
  Text,
  InputGroup,
  InputRightElement,
  useColorModeValue,
} from "@chakra-ui/react";
// Assets
import negativeLogo from "../../assets/img/negative-logo.svg";
import {post} from "../../client/client";
import {useNavigate, useParams} from "react-router-dom";

const ResetPasswordStatus = {
  SENT: "sent",
  NOT_SENT: "not_sent",
}

const PasswordInput = ({password, onChangePassword, ...rest}) => {
  const [show, setShow] = React.useState(false)
  const handleClick = () => setShow(!show)

  return (
    <InputGroup size='md'>
      <Input
        borderRadius='15px'
        mb='36px'
        fontSize='sm'
        type={show ? 'text' : 'password'}
        placeholder='Your password'
        size='lg'
        autocomplete="new-password"
        onChange={(e) => onChangePassword(e.target.value)}
        value={password}
        {...rest}
      />
      <InputRightElement width='4.5rem' style={{marginTop: "4px", marginRight: "3px"}}>
        <Button h='1.75rem' size='sm' onClick={handleClick}>
          {show ? 'Hide' : 'Show'}
        </Button>
      </InputRightElement>
    </InputGroup>
  )
}


function ResetPassword() {
  const textColor = useColorModeValue("gray.400", "white");
  const {token} = useParams();
  const navigate = useNavigate();
  const [password, setPassword] = React.useState("");
  const [password2, setPassword2] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [resetPasswordStatus, setResetPasswordStatus] = React.useState(ResetPasswordStatus.NOT_SENT);
  const [loading, setLoading] = React.useState(false);
  const [passwordInvalid, setPasswordInvalid] = React.useState(false);

  const resetPassword = () => {
    if (password.length < 8) {
      setErrorMessage("Password must be at least 8 characters");
      setPasswordInvalid(true);
      return;
    }
    if (password !== password2) {
      setErrorMessage("Passwords do not match");
      setPasswordInvalid(true);
      return;
    }

    setPasswordInvalid(false);
    setLoading(true);

    post("reset_password", {
      password,
      token,
    }).then((response) => {
      setLoading(false);
      setErrorMessage(null);
      setResetPasswordStatus(ResetPasswordStatus.SENT);
    }).catch((error) => {
      setLoading(false);
      setErrorMessage(error.response.data.error);
    });
  }

  const onKeyDown = (event) => {
    if (event.key === "Enter") {
      resetPassword();
    }
  }

  return (
    <Flex position='relative' mb='40px' style={{width: "100%"}}>
      <Flex
        h={{sm: "initial", md: "75vh", lg: "100vh"}}
        w='100%'
        // maxW='1044px'
        mx='auto'
        justifyContent='space-between'
        mb='30px'
        pt={{sm: "100px", md: "0px"}}>

        <Box
          display={{base: "none", md: "block"}}
          overflowX='hidden'
          h='100%'
          w='35%'
          left='0px'>
          <Box
            bg={useColorModeValue("blue.700", "gray.800")}
            w='100%'
            h='100%'
            bgSize='cover'
            borderBottomRightRadius='20px'>
            <Center style={{height: "100%"}}>
              <Flex flexDirection={"column"}>
                <Center>
                  <Image src={negativeLogo} w='100%' h='50%'/>
                </Center>
              </Flex>
            </Center>
          </Box>
        </Box>


        <Flex
          alignItems='center'
          justifyContent='start'
          style={{userSelect: "none", width: "50%"}}
          // w={{ base: "100%", md: "50%", lg: "42%" }}
        >

          <div style={{width: "100%"}}>
            <Flex
              direction='column'
              style={{maxWidth: "600px"}}
              background='transparent'
              p='0px'
              mt={{md: "150px", lg: "80px"}}>
              <Heading color={"blue.700"} fontSize='32px' mb='10px'>
                Reset Your Password
              </Heading>
              {
                resetPasswordStatus === ResetPasswordStatus.SENT ?
                  <>
                    <Alert status='success' style={{marginTop: "5%", marginBottom: "5%"}}>
                      <AlertIcon/>
                      Success! Your password has been updated.
                    </Alert>
                    <div>
                      <Button
                        onClick={() => navigate("/auth/sign_in", {replace: false})}
                        fontSize='13pt'
                        type='submit'
                        bg='teal.300'
                        color='white'
                        w='50%'
                        h='45'
                        mb='20px'
                        mt='20px'
                        _hover={{
                          bg: "teal.200",
                        }}
                        _active={{
                          bg: "teal.400",
                        }}>
                        Return to login
                      </Button>
                    </div>
                  </> :
                  <>
                    <Text
                      mb='36px'
                      ms='4px'
                      color={textColor}
                      fontWeight='bold'
                      fontSize='14px'>
                      Please enter your new password.
                    </Text>
                    <FormControl onSubmit={resetPassword}>
                      <FormLabel ms='4px' fontSize='sm' fontWeight='bold' color={"blue.700"}>
                        New password
                      </FormLabel>
                      <PasswordInput
                          placeholder='Your new password'
                          isInvalid={passwordInvalid}
                          onKeyDown={onKeyDown}
                          password={password}
                          onChangePassword={setPassword}
                      />
                      <FormLabel ms='4px' fontSize='sm' fontWeight='bold' color={"blue.700"}>
                        Confirm new password
                      </FormLabel>
                      <PasswordInput
                          placeholder='Your new password'
                          isInvalid={passwordInvalid}
                          onKeyDown={onKeyDown}
                          password={password2}
                          onChangePassword={setPassword2}
                      />
                      {errorMessage &&
                        <Alert status='error'>
                          <AlertIcon/>
                          {errorMessage}
                        </Alert>
                      }
                      <Button
                        onClick={resetPassword}
                        onSubmit={resetPassword}
                        isLoading={loading}
                        fontSize='13pt'
                        type='submit'
                        bg='teal.300'
                        color='white'
                        w='50%'
                        h='45'
                        mb='20px'
                        mt='20px'
                        _hover={{
                          bg: "teal.200",
                        }}
                        _active={{
                          bg: "teal.400",
                        }}>
                        Reset Password
                      </Button>
                    </FormControl>
                  </>
              }
            </Flex>
          </div>
        </Flex>


      </Flex>
    </Flex>
  );
}

export default ResetPassword;
